import { Suspense, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import View3D from './pages/Viewer';
import NoMatch from './pages/NoMatch';
import Edl3D from './pages/Edl3D';
import './App.css';
import { Form } from './pages/declarator/form';
import { Validate } from './pages/declarator/validate';
import { Document } from './pages/declarator/document';
import DocumentReader from './pages/documentReader/DocumentReader';
import DeclareEvent from './pages/DeclareEvent/DeclareEvent';
import LocationListener from '../utils/LocationListener';

export default function AppRouter({ ReactGA }) {
  const LoadingMessage = () => <div>Loading..,</div>;
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [spinnerOpen, setSpinnerOpen] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const value = { isAuthenticated, setIsAuthenticated };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [organization, setOrganization] = useState(null);
  const [productInfos, setProductInfos] = useState(null);
  const [fullData, setFullData] = useState(null);
  const [emailHandlers, setEmailHandlers] = useState(null);

  let idEvent = '';
  let idProduct = '';
  let eventPayload = null;
  let file = null;
  let tags = [];
  let filesTags = [];

  const declarantInfos = {
    identity: '',
    phone: '',
    email: ''
  };

  const setIdEvent = (value) => {
    idEvent = value;
  };

  const getIdEvent = (value) => {
    return idEvent;
  };

  const setIdProduct = (value) => {
    idProduct = value;
  };

  const getIdProduct = (value) => {
    return idProduct;
  };

  const getOrganization = (value) => {
    return organization;
  };

  const setEventPayload = (data) => {
    eventPayload = data;
  };

  const getEventPayload = () => {
    return eventPayload;
  };

  const setFile = (media) => {
    // console.log(media);
    file = media;
  };

  const getFile = () => {
    return file;
  };

  const setTags = (tagsList) => {
    // console.log(tagsList);
    // console.log(getOrganization());
    tags = tagsList;
  };

  const getTags = () => {
    return tags;
  };

  const setTagsAssociatedFiles = (filesTagsList) => {
    // console.log(tagsList);
    // console.log(getOrganization());
    filesTags = filesTagsList;
  };

  const getTagsAssociatedFiles = () => {
    return filesTags;
  };

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingMessage />}>
        <LocationListener ReactGA={ReactGA} />
        <Routes>
          <Route path="view3D/:idProduct" element={<View3D mode="view3D" />}></Route>

          <Route path="edl/:idEdl" element={<Edl3D />}></Route>

          <Route path="viewEvent/:idEvent" element={<View3D mode={'administrate'} />}></Route>
          <Route path="viewProvision/:idProvision" element={<View3D mode={'declare'} />}></Route>
          <Route
            path="declareEvent_old/:idProduct"
            element={
              <Form
                mode={'declare'}
                declarantInfos={declarantInfos}
                setIdEvent={setIdEvent}
                getIdEvent={getIdEvent}
                setIdProduct={setIdProduct}
                getIdProduct={getIdProduct}
                setOrganizationData={setOrganization}
                getOrganization={getOrganization}
                setName={setName}
                setEventPayload={setEventPayload}
                getEventPayload={getEventPayload}
                setProductInfos={setProductInfos}
                setFullData={setFullData}
                getFullData={fullData}
                getProductInfos={productInfos}
                setEmailHandlers={setEmailHandlers}
                name={name}
                setEmail={setEmail}
                email={email}
                setPhone={setPhone}
                phone={phone}
              />
            }
          ></Route>
          <Route path="declareEvent/:idProduct" element={<DeclareEvent></DeclareEvent>}></Route>
          <Route
            path="tagEvent/:idProduct"
            element={
              <View3D
                mode={'declare'}
                setTags={setTags}
                setTagsAssociatedFiles={setTagsAssociatedFiles}
                getTagsAssociatedFiles={getTagsAssociatedFiles}
                getProductInfos={productInfos}
                getEventPayload={getEventPayload}
                getOrganization={getOrganization}
                getEmailHandlers={emailHandlers}
              />
            }
          ></Route>
          <Route
            path="documentEvent/:idProduct"
            element={
              <Document
                getFile={getFile}
                getTags={getTags}
                setTags={setTags}
                getIdEvent={getIdEvent}
                getEventPayload={getEventPayload}
                getOrganization={getOrganization}
                getProductInfos={productInfos}
                getEmailHandlers={emailHandlers}
                setFile={setFile}
              />
            }
          />
          <Route
            path="validateEvent/:idProduct"
            element={
              <Validate
                getFile={getFile}
                getTags={getTags}
                getIdEvent={getIdEvent}
                getProductInfos={productInfos}
                getEventPayload={getEventPayload}
                getOrganization={getOrganization}
              />
            }
          />
          <Route path="declareProvision/:idProduct" element={<View3D mode={'declare'} />}></Route>
          <Route path="doc/:idFile" element={<DocumentReader />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

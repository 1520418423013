import React, { useEffect, useRef, useState, Suspense } from 'react';
import { Box, AppBar, Toolbar, Typography, useMediaQuery, Dialog, DialogContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { toolBarEDLIcons } from '../../../utils/references';
import { ViewerIcon } from './PannelsEDL/ViewerIcon';
import { PanelContent } from './PannelsEDL/PanelContent';
import _ from 'lodash';
import { setupSdk } from '@matterport/sdk';
import './MatterportViewer.scss';

let sweeps = {};

const pannels = [
  { name: 'informations', hasSub: false, idPanel: 0, label: 'Informations', hasLocalisations: false },
  { name: 'annexes', hasSub: false, idPanel: 1, label: 'Annexes', hasLocalisations: false },
  {
    name: 'externalEquipments',
    hasSub: false,
    idPanel: 2,
    label: 'Equipements extérieurs',
    hasLocalisations: true,
    icon: 'public_characters_circle-e',
    tagColor: { r: 0 / 255, g: 153 / 255, b: 51 / 255 }
  },
  {
    name: 'generalEquipments',
    hasSub: true,
    idPanel: 3,
    label: 'Equipements généraux',
    hasLocalisations: true,
    icon: 'public_characters_circle-e',
    tagColor: { r: 0 / 255, g: 153 / 255, b: 51 / 255 }
  },
  {
    name: 'meters',
    hasSub: false,
    idPanel: 4,
    label: 'Relevé des compteurs',
    hasLocalisations: true,
    icon: 'public_characters_circle-e',
    tagColor: { r: 0 / 255, g: 153 / 255, b: 51 / 255 }
  },
  {
    name: 'degradations',
    hasSub: true,
    idPanel: 5,
    label: 'Dégradations',
    hasLocalisations: true,
    icon: 'public_symbols_exclamation-square',
    tagColor: { r: 255 / 255, g: 204 / 255, b: 0 / 255 }
  },
  {
    name: 'inventoryEquipments',
    hasSub: true,
    idPanel: 6,
    label: 'Inventaire',
    hasLocalisations: true,
    icon: 'public_characters_circle-e',
    tagColor: { r: 0 / 255, g: 153 / 255, b: 51 / 255 }
  },
  { name: 'generalState', hasSub: false, idPanel: 7, label: 'Etat général du bien', hasLocalisations: false },
  { name: 'keys', hasSub: false, idPanel: 8, label: 'Remise des clés', hasLocalisations: false }
];

export const MatterportViewerContainer = ({
  mpDh,
  mpLang,
  mpSearch,
  setSpinnerOpen,
  spinnerOpen,
  setModalOpen,
  refreshData,
  isBuilding = false,
  propertyData,
  setPropertyData,
  context = 'EDL_3D_Viewer', // viewer || EDL_3D_Tech || EDL_3D_Viewer
  selectedEdl,
  selectedOdm,
  modelId,
  selectedDataOrder,
  uuidMatterportScan,
  uuid_scan,
  referencial,
  referencialEquipment,
  datas
}: any) => {
  const { REACT_APP_MATTERPORT_KEY } = process.env;
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  // States

  const [mainHeight, setMainHeight] = React.useState(0);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const [matterportViewerWidth, setMatterportViewerWidth] = React.useState('');
  const [pannelWidth, setPannelWidth] = React.useState(500);
  const [mpSdk, setMpSDK] = useState(null);
  const [activePanel, setActivePanel] = useState(0);
  const [panelOpened, _setPanelOpened] = useState(true);
  const [openFirstHelper, setOpenFirstHelper] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [idEdl, setIdEdl] = useState(null);
  const [dataObject, setDataObject] = useState(null);
  const [sweepCollection, setSweepCollection] = useState(null);
  const [sidsTags, setSidsTags] = useState([]);
  const [correspondanceTagToSweep, _setCorrespondanceTagToSweep] = useState({});
  const [placeTagMode, _setPlaceTagMode] = useState(true);
  const [currentTagTobePlaced, _setCurrentTagToBePlaced] = useState(null);
  const [selectedRoom, _setSelectedRoom] = useState(null);
  const [rooms, _setRooms] = useState({});
  const [currentTagPlaced, _setCurrentTagPlaced] = useState(false);
  const [imageLocalisation, setImageLocalisation] = useState(null);
  const [appStatePlaying, setAppStatePlaying] = useState(false);
  const [zoomPhotoOpen, setZoomPhotoOpen] = useState(false);
  const [zoomPhotoSrc, setZoomPhotoSrc] = useState(null);
  const [tagsData, _setTagsData] = useState({});

  const [imageOrientation, setImageOrientation] = useState(null);

  const [subCategoriesByPanels, setSubCategoriesByPanels] = useState({});

  const activePanelRef = useRef(activePanel);

  const [selection, setSelection] = useState({
    selectedImage: null,
    selectEquipment: null,
    selectSubCategory: null,
    selectCategory: null,
    selectedSid: null,
    prevSid: null
  });

  const [navigationFinished, setNavigationFinished] = useState(true);

  // REFS
  const tagDataRef = useRef(null); //placement of mattertag
  const matterPortFrame = useRef<HTMLIFrameElement>(null);
  const roomsRef = useRef(rooms);
  const circleIndicator = useRef(null); //placement of circleIndicator
  const circleIndicatorBackGround = useRef(null); //placement of circleIndicatorBackGround
  const circleIndicatorFill = useRef(null); //placement of circleIndicatorFill
  const panelOpenedRef = useRef(panelOpened);
  const placeTagModeRef = useRef(placeTagMode);
  const currentTagTobePlacedRef = useRef(currentTagTobePlaced);
  const currentTagPlacedRef = useRef(currentTagPlaced);
  const tagsDataRef = useRef(tagsData);

  const prevTagSid = useRef(null);

  // setter state react
  const setPanelOpened = (data) => {
    panelOpenedRef.current = data;
    _setPanelOpened(data);
  };

  const setRooms = (data) => {
    roomsRef.current = data;
    const tmpRooms = JSON.parse(JSON.stringify(data));
    _setRooms(tmpRooms);
  };

  const handleZoomImage = (img, orientation) => {
    setImageOrientation(orientation);
    setZoomPhotoSrc(img);
    setZoomPhotoOpen(true);
  };

  const layoutWithPannel = matchesMd || matchesLg || matchesXl || window.innerWidth > window.innerHeight;

  const header = useRef(null);
  const toolbar = useRef(null);

  const onClickIconHandler = (iconId) => {
    setPanelOpened(true);
    setActivePanel(iconId);
    clickPanelButton(iconId);
  };

  const hideTags = async (tags) => {
    tags.map((tag) => {
      mpSdk.Tag.editOpacity(tag, 0);
      mpSdk.Tag.editStem(tag, { stemHeight: 1000 });
    });
  };

  const showTags = (tags, stemHeight) => {
    tags.map((tag) => {
      mpSdk.Tag.editOpacity(tag, 1);
      mpSdk.Tag.editStem(tag, { stemHeight: stemHeight });
    });
  };

  const handleZoomPhotoClose = () => {
    setZoomPhotoOpen(false);
  };

  const onResizeHandler = () => {
    let pannelWidthVar = 0;
    if (panelOpenedRef.current) {
      if (window.innerWidth < 600) {
        if (window.innerWidth > window.innerHeight) {
          pannelWidthVar = 300;
        } else {
          pannelWidthVar = 0;
        }
      } else if (window.innerWidth < 900) {
        if (window.innerWidth > window.innerHeight) {
          pannelWidthVar = 300;
        } else {
          pannelWidthVar = 0;
        }
      } else if (window.innerWidth < 1200) {
        pannelWidthVar = 300;
      } else if (window.innerWidth < 1536) {
        pannelWidthVar = 400;
      } else if (window.innerWidth >= 1536) {
        pannelWidthVar = 600;
      }
      if (context === 'EDL_3D_Tech') {
        setMatterportViewerWidth(`${window.innerWidth - pannelWidthVar}px`);
      } else {
        setMatterportViewerWidth(`${window.innerWidth - pannelWidthVar}px`);
      }
    } else {
      if (context === 'EDL_3D_Tech') {
        setMatterportViewerWidth(`${window.innerWidth}px`);
      } else {
        setMatterportViewerWidth(`${window.innerWidth}px`);
      }
    }
    setPannelWidth(pannelWidthVar);
    if (header !== null && header !== undefined && header.current !== null && header.current !== undefined) {
      setHeaderHeight(header.current.clientHeight);
      setMainHeight(window.innerHeight - header.current.clientHeight);
    }
    // if (!layoutWithPannel && window.innerWidth > window.innerHeight) {
    //   layoutWithPannel = true;
    // }
  };

  const updateTagPosition = (newPos, newNorm = undefined, scale = undefined) => {
    if (!newPos) return;
    if (!scale) scale = 0.2;
    if (!newNorm) newNorm = { x: 0, y: 1, z: 0 };

    tagDataRef.current = {
      anchorPosition: newPos,
      stemVector: {
        x: scale * newNorm.x,
        y: scale * newNorm.y,
        z: scale * newNorm.z
      }
    };

    mpSdk.Tag.editPosition(currentTagTobePlacedRef.current, tagDataRef.current)
      .then(() => {
        setImageLocalisation((prev) => {
          return { ...prev, ...{ placed: false, sid: currentTagTobePlacedRef.current, data: tagDataRef.current } };
        });
      })
      .catch((e) => {
        currentTagTobePlacedRef.current = null;
        tagDataRef.current = null;
      });
  };

  const boxRef = useRef(null);

  const generateTagByCategory = (category, hasSub) => {
    let groupedEquipments = {};
    if (category === 'inventoryEquipments') {
      datas[category].forEach((equipment) => {
        const catName = _.find(referencialEquipment['inventoryEquipmentKinds'], {
          id: Number(equipment.sub_tab)
        }).name;
        if (groupedEquipments[catName]) {
          groupedEquipments[catName].push(equipment);
        } else {
          groupedEquipments[catName] = [equipment];
        }
      });
    } else {
      groupedEquipments = hasSub
        ? _.groupBy(datas[category], category === 'degradations' ? 'room' : 'sub_tab')
        : { uniquGroup: datas[category] };
    }

    setSubCategoriesByPanels((prev) => ({
      ...prev,
      [category]: Object.keys(groupedEquipments)
    }));

    const promises = [];

    Object.keys(groupedEquipments).map(async (group, groupIndex) => {
      await groupedEquipments[group].map(async (equip, indexEquipment) => {
        if (equip.states && equip.states[0].tags && equip.states[0].tags.length > 0) {
          await equip.states[0].tags?.map(async (tag, indexTag) => {
            // if (indexTag < equip.states[0].tags.length - 1) {
            //   return;
            // }

            if (!tag.images) return null;
            await promises.push(
              mpSdk.Tag.add({
                enabled: true,
                anchorPosition: {
                  x: tag.position.x,
                  y: tag.position.y,
                  z: tag.position.z
                },
                stemVector: {
                  // make the Tag stick straight up and make it 0.30 meters (~1 foot) tall
                  x: tag.position.steam_x,
                  y: tag.position.steam_y,
                  z: tag.position.steam_z
                },
                color: _.find(pannels, { name: category }).tagColor
              }).then(async (tag) => {
                mpSdk.Tag.editStem(tag[0], { stemHeight: 0.3 }).then(async (res) => {
                  await mpSdk.Tag.editIcon(tag[0], _.find(pannels, { name: category }).icon).then((res) => {
                    const tmp = JSON.parse(JSON.stringify(tagsDataRef.current));
                    tmp[tag[0]] = {
                      type: 'imageLocalisation',
                      category: category,
                      subCategory: hasSub ? (category === 'degradations' ? equip.room : equip.sub_tab) : null,
                      idEquipment: indexEquipment,
                      idImage: indexTag,
                      idTag: tag[0],
                      placed: false
                    };
                    tagsDataRef.current = tmp;
                    mpSdk.Tag.allowAction(tag[0], {
                      navigating: true,
                      opening: false
                    });
                    hideTags([tag[0]]);
                  });
                });
              })
            );
          });
        }
      });
    });

    Promise.all(promises).then(async () => {
      showTags(Object.keys(tagsDataRef.current), 0.2);
    });
  };

  useEffect(() => {
    if (mpSdk) {
      if (datas && datas.externalEquipments) {
        generateTagByCategory('externalEquipments', false);
      }
      if (datas && datas.generalEquipments) {
        generateTagByCategory('generalEquipments', true);
      }
      if (datas && datas.meters) {
        generateTagByCategory('meters', false);
      }
      if (datas && datas.degradations) {
        generateTagByCategory('degradations', true);
      }
      if (datas && datas.inventoryEquipments) {
        generateTagByCategory('inventoryEquipments', true);
      }
    }
  }, [datas, mpSdk]);

  useEffect(() => {
    // console.log('selectCategory', selection.selectCategory);
    // console.log('selectedImage', selection.selectedImage);
    // console.log('selectEquipment', selection.selectEquipment);
    // console.log('selectSubCategory', selection.selectSubCategory);
    // console.log('dataObject', dataObject);
    // console.log('tagsDataRef', tagsDataRef.current);
    // console.log('currentTagTobePlacedRef', currentTagTobePlacedRef.current);
    const potentialTag = _.find(tagsDataRef.current, {
      category: selection.selectCategory,
      subCategory: selection.selectSubCategory ? selection.selectSubCategory.toString() : null,
      idEquipment: selection.selectEquipment,
      idImage: selection.selectedImage
    });

    // console.log('potentialTag', potentialTag);

    if (potentialTag) {
      mpSdk.Tag.open(potentialTag.idTag).then((res) => {
        // console.log('Navigation started');
        mpSdk.Mattertag.navigateToTag(potentialTag.idTag)

          .then((tag) => {
            // mpSdk.Tag.open(potentialTag.idTag).then((res) => {});
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            // console.log('Navigation finished');
          });
      });
    }

    if (selection.selectCategory !== null)
      setActivePanel(pannels.findIndex((pannel) => pannel.name === selection.selectCategory));
  }, [selection]);

  useEffect(() => {
    activePanelRef.current = activePanel;
    const tagsByCategory = _.groupBy(tagsDataRef.current, 'category');
    const tagsToShow = tagsByCategory[pannels[activePanel] ? pannels[activePanel].name : null];
    const idsToShow = tagsToShow ? tagsToShow.map((tag) => tag.idTag) : null;

    if (idsToShow) {
      const idsToHide = Object.keys(tagsDataRef.current).filter((tag) => !idsToShow.includes(tag));

      hideTags(idsToHide);

      showTags(idsToShow, 0.2);
    } else {
      const allTags = Object.keys(tagsDataRef.current);
      showTags(allTags, 0.2);
    }
  }, [activePanel]);

  const clickPanelButton = (activePanel) => {
    setSelection((prevState) => ({
      ...prevState,
      selectCategory: pannels[activePanel] ? pannels[activePanel].name : null,
      selectedImage: null,
      selectEquipment: null,
      selectSubCategory: pannels[activePanel]
        ? pannels[activePanel].hasSub
          ? pannels[activePanel].name !== 'degradations' && pannels[activePanel].name !== 'inventoryEquipments'
            ? subCategoriesByPanels[pannels[activePanel].name]
              ? subCategoriesByPanels[pannels[activePanel].name][0]
              : null
            : subCategoriesByPanels[pannels[activePanel].name][0]
          : null
        : null
    }));
  };

  useEffect(() => {
    const roomsTemp = {};
    dataObject?.rooms?.map((room) => {
      roomsTemp[room.uuid_room] = {
        uuid_room: room.uuid_room,
        name: room.name,
        sweep: !room.sweep ? [] : [...room.sweep]
      };
    });
    setRooms(roomsTemp);
    // console.log('dataObject', dataObject);
  }, [dataObject]);

  useEffect(() => {
    rooms[selectedRoom]?.sweep?.map((sweep) => {});
  }, [selectedRoom, rooms]);

  useEffect(() => {
    setIdEdl(selectedDataOrder?.edl?.uuid);
  }, [idEdl]);

  useEffect(() => {
    setDataObject(selectedEdl);
  }, [selectedEdl]);

  useEffect(() => {
    onResizeHandler();
    window.addEventListener('resize', onResizeHandler);
    onClickIconHandler(0);
    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);

  useEffect(() => {
    onResizeHandler();
  }, [panelOpened]);

  useEffect(() => {
    if (matterPortFrame.current) {
      setupSdk(REACT_APP_MATTERPORT_KEY, {
        container: matterPortFrame.current,
        space: modelId,
        iframeQueryParams: { tagNav: 0, search: 0, lang: 'fr', vr: 0, brand: 0, mls: 2 }
      }).then((resp) => {
        setMpSDK(resp);
      });
    }
  }, [matterPortFrame]);

  useEffect(() => {
    if (mpSdk) {
      mpSdk.App.state.subscribe((appState) => {
        if (appState.phase === 'appphase.playing') {
          setAppStatePlaying(true);
          mpSdk.Pointer.intersection.subscribe((intersectionData) => {
            if (
              placeTagModeRef.current == true &&
              currentTagTobePlacedRef.current !== null &&
              currentTagPlacedRef.current !== true &&
              placeTagModeRef.current == true
            ) {
              updateTagPosition(intersectionData.position, intersectionData.normal);
            }
          });
        }
      });

      if (sidsTags.length < 1) {
        if (isFirstTime) setOpenFirstHelper(true);
        setIsFirstTime(false);
        mpSdk.Sweep.data.subscribe({
          onAdded: function (index, item, collection) {
            sweeps[item.id] = { sweepSid: item.id };
          },
          onRemoved: function (index, item, collection) {},
          onUpdated: function (index, item, collection) {},
          onCollectionUpdated: function (collection) {
            setSweepCollection(collection);
          }
        });

        mpSdk.Tag.data.subscribe({
          onAdded: function (index, item, collection) {
            const arrSids = [];
            for (const [key, value] of Object.entries(collection)) {
              arrSids.push(key);
            }
            setSidsTags(arrSids);
          },
          onRemoved: function (index, item, collection) {
            // console.log('removed', item, item, collection);
            const arrSids = [];
            for (const [key] of Object.entries(collection)) {
              arrSids.push(key);
            }

            setSidsTags(arrSids);
          },
          onUpdated: function (index, item, collection) {
            // console.log('updated', item, item, collection);
          },
          onCollectionUpdated: function (collection) {
            // console.log('collection updated', collection);
          }
        });

        mpSdk.Tag.openTags.subscribe({
          prevState: {
            hovered: null,
            docked: null,
            selected: null
          },
          onChanged(newState) {
            if (newState.hovered !== this.prevState.hovered) {
              if (newState.hovered) {
                // console.log(newState.hovered, 'was hovered');
              } else {
                // console.log(this.prevState.hovered, 'is no longer hovered');
              }
            }
            if (newState.docked !== this.prevState.docked) {
              if (newState.docked) {
                // console.log(newState.docked, 'was docked');
              } else {
                // console.log(this.prevState.docked, 'was undocked');
              }
            }

            // only compare the first 'selected' since only one tag is currently supported
            const [selected = null] = newState.selected; // destructure and coerce the first Set element to null

            if (selected !== this.prevState.selected && tagsDataRef.current[selected]) {
              if (tagsDataRef.current[selected]) {
                setSelection((prevState) => ({
                  ...prevState,
                  selectCategory: tagsDataRef.current[selected].category,
                  selectEquipment: tagsDataRef.current[selected].idEquipment,
                  selectedImage: tagsDataRef.current[selected].idImage,
                  selectSubCategory: tagsDataRef.current[selected].subCategory,
                  selectedTag: selected,
                  prevSid: prevTagSid.current
                }));
                prevTagSid.current = selected;
              }
            }

            this.prevState = {
              ...newState,
              selected
            };
          }
        });
      }
    }
  }, [mpSdk]);

  return (
    <div
      style={{
        pointerEvents: !navigationFinished ? 'none' : 'auto',
        position: 'fixed',
        height: '100dvh',
        width: '100dvw',
        margin: 0
      }}
    >
      <Suspense fallback={<div>Loading</div>}>
        <Dialog
          onClose={handleZoomPhotoClose}
          open={zoomPhotoOpen}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: imageOrientation === 'landscape' ? '100dvw' : 'auto',
                maxWidth: imageOrientation === 'landscape' ? `${window.innerHeight}px` : 'auto'
              },
              '& .MuiDialogContent-root': {
                padding: '10px !important',
                paddingBottom: '7px !important'
              }
            }
          }}
        >
          <DialogContent
            style={{
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          >
            <img src={zoomPhotoSrc} style={{ maxWidth: '100%', maxHeight: 'calc(100dvh - 104px)' }} />
          </DialogContent>
        </Dialog>

        <Box sx={{ display: 'flex', height: '100dvh', zIndex: 999999, margin: 0 }}>
          <AppBar ref={header} position="fixed" sx={{ height: '40px', zIndex: 99999, pointerEvents: 'none' }}>
            <Toolbar>
              <Typography variant="h6" noWrap component="div" sx={{ mb: matchesSm ? 3 : 0, mt: matchesSm ? 0 : 1 }}>
                {`${propertyData.address.street} ${propertyData.address.zip_code} ${propertyData.address.city} ${
                  propertyData.address_complement ? propertyData.address_complement : ''
                }`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            id="matterportContainer"
            component="main"
            sx={
              layoutWithPannel
                ? {
                    width: matterportViewerWidth,
                    position: 'absolute',
                    top: `${headerHeight}px`,
                    height: `${mainHeight}px`,
                    paddingLeft: 0,
                    paddingRight: 0,
                    backgroundColor: '#1C1C1E',
                    overflow: 'hidden',
                    margin: 0
                  }
                : {
                    width: `calc(100%)`,
                    position: 'absolute',
                    top: `${40}px`,
                    height: panelOpened
                      ? `calc(50% - 40px)`
                      : context === 'EDL_3D_Tech'
                        ? `calc(100% - 40px)`
                        : `calc(100% - 80px)`,
                    paddingLeft: 0,
                    paddingRight: 0,
                    backgroundColor: '#1C1C1E',
                    overflow: 'hidden'
                  }
            }
          >
            <>
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  ref={circleIndicator}
                  version="1.1"
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    pointerEvents: 'none',
                    transform: 'rotate(-90deg)',
                    zIndex: 999999999
                  }}
                  width="200"
                  height={200}
                  visibility="hidden"
                >
                  <circle cx="100" cy="100" r="98" stroke="grey" strokeWidth="5" fill="none" />
                  <circle
                    ref={circleIndicatorBackGround}
                    cx="100"
                    cy="100"
                    r="98"
                    strokeWidth="0"
                    fill="white"
                    opacity={0.1}
                  />
                  <circle
                    ref={circleIndicatorFill}
                    cx="100"
                    cy="100"
                    r="98"
                    stroke="#F17633"
                    strokeWidth="5"
                    fill="none"
                    strokeDasharray={613}
                    strokeDashoffset={613}
                  />
                </svg>
              </div>
            </>
            <div
              ref={matterPortFrame}
              style={
                layoutWithPannel
                  ? {
                      border: 'none',
                      margin: 0,
                      padding: 0,
                      height: `${mainHeight}px`,
                      width: matterportViewerWidth,
                      overflow: 'hidden',
                      position: 'absolute'
                    }
                  : {
                      border: 'none',
                      margin: 0,
                      padding: 0,
                      height: `100%`,
                      width: `100%`,
                      overflow: 'hidden'
                    }
              }
              title="viewer"
            ></div>
          </Box>
          <Box
            ref={boxRef}
            sx={
              layoutWithPannel
                ? {
                    right: context === 'EDL_3D_Tech' ? 0 : 0,
                    width: `${pannelWidth}px`,
                    position: 'absolute',
                    top: `${headerHeight}px`,
                    overflow: 'hidden',
                    height: `${mainHeight}px`
                  }
                : {
                    right: 0,
                    width: `100%`,
                    position: 'absolute',
                    bottom: `${50}px`,
                    overflow: 'hidden',
                    height: !panelOpened ? '0px' : context === 'EDL_3D_Tech' ? `calc(50% )` : `calc(50% - 50px)`
                  }
            }
          >
            <PanelContent
              activePanel={activePanel}
              propertyData={propertyData}
              layoutWithPannel={layoutWithPannel}
              context={context}
              selectedEdl={selectedEdl}
              selectedOdm={selectedOdm}
              referencial={referencial}
              referencialEquipment={referencialEquipment}
              datas={datas}
              selection={selection}
              handleZoomImage={handleZoomImage}
              setSelection={setSelection}
              panelData={_.find(pannels, { idPanel: activePanel })}
            ></PanelContent>
          </Box>

          {(context === 'viewer' || context === 'EDL_3D_Viewer') && (
            <AppBar
              ref={toolbar}
              position="fixed"
              style={{ margin: 0, padding: 0, boxShadow: 'none' }}
              sx={
                layoutWithPannel
                  ? {
                      top: '40px',
                      width: '40px',
                      pr: 0,
                      m: 0,
                      p: 0,
                      backgroundColor: '#B19E8C',
                      right: `calc(100% - ${matterportViewerWidth})`
                    }
                  : {
                      top: panelOpened ? 'calc(100% - 40px)' : 'calc(100% - 40px)',
                      height: `40px`,
                      width: '100%',
                      backgroundColor: 'transparent'
                    }
              }
            >
              {layoutWithPannel &&
                datas &&
                toolBarEDLIcons.map((icon, indexIcon) => {
                  if (datas[icon.key] && datas[icon.key].length > 0)
                    return (
                      <ViewerIcon
                        key={icon.id}
                        isActive={activePanel}
                        iconId={icon.id}
                        iconName={icon.name}
                        setActivePanel={onClickIconHandler}
                        layoutWithPannel={layoutWithPannel}
                        context={context}
                      />
                    );
                })}

              {!layoutWithPannel && (
                <Toolbar
                  sx={{
                    position: 'absolute',
                    backgroundColor: '#B19E8C',
                    minHeight: '0px !important',
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important',
                    bottom: '0px !important'
                  }}
                >
                  {datas &&
                    toolBarEDLIcons.map((icon, index) => {
                      if (datas[icon.key] && datas[icon.key].length > 0)
                        return (
                          <div key={index}>
                            <ViewerIcon
                              key={icon.id}
                              isActive={activePanel}
                              iconId={icon.id}
                              iconName={icon.name}
                              setActivePanel={onClickIconHandler}
                              layoutWithPannel={layoutWithPannel}
                              context={context}
                            />
                          </div>
                        );
                    })}
                </Toolbar>
              )}
            </AppBar>
          )}
        </Box>
      </Suspense>
    </div>
  );
};

export default MatterportViewerContainer;

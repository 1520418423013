import {
  getEvent,
  getProduct,
  axiosClient,
  postEvent,
  submitTags,
  SendMailToClient,
  SendMailToGest,
  createFile,
  referentielEventTypes
} from '../../api';
import { useParams, useNavigate } from 'react-router-dom';
import MatterPort from './blocs/Matterport';
import React, { useEffect, useRef, useState } from 'react';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Dialog, Button, SvgIcon, useTheme, useMediaQuery, Box, Typography, Grid } from '@mui/material';
import { UploadFile } from './blocs/UploadFile';
import { getIcon } from 'material-file-icons';
import { onActorPropertiesChangedEvent, PhotonClient } from '~/utils/PhotonClient';
import { CameraPose, Event, Frame, IVector3 } from '~/utils/types';
import { LocalTime } from '~/utils/time/LocalTime';

export interface View3DProps {
  mode: any;
  setTags?: any;
  getProductInfos?: any;
  setTagsAssociatedFiles?: any;
  getTagsAssociatedFiles?: any;
  getEventPayload?: any;
  getOrganization?: any;
  getEmailHandlers?: any;
}

const View3D = ({
  mode,
  setTags,
  getProductInfos,
  setTagsAssociatedFiles,
  getTagsAssociatedFiles,
  getEventPayload,
  getOrganization,
  getEmailHandlers
}: View3DProps) => {
  let navigate = useNavigate();
  const { idProduct } = useParams();
  const { idEvent } = useParams();
  const [idModel, setIdModel] = useState();
  const [idPro, setIdProduct] = useState('');
  const [eventDatas, setEventDatas] = useState<any>();
  const [MattertagsList, setMattertagsList] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = useState(false);
  const [productDatas, setProductDatas] = useState<any>();
  const [filesTags, setFilesTags] = useState([]);
  const boxRef = useRef(null);

  const [matterportViewerWidth, setMatterportViewerWidth] = React.useState('');
  const [files, setFiles] = useState([]);

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  const layoutWithPannel = matchesMd || matchesLg || matchesXl || window.innerWidth > window.innerHeight;

  const [mainHeight, setMainHeight] = React.useState(0);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const [isPortrait, setIsPortrait] = useState(true);

  const [sceneReady, setSceneReady] = useState(false);

  const [tagToNavigate, setTagToNavigate] = useState(null);
  const [mpSdk, setMpSdk] = useState(null);

  const [enteredInRoom, setEnteredInRoom] = useState(false);

  const setPanelOpened = (data) => {
    panelOpenedRef.current = data;
    _setPanelOpened(data);
  };

  const [panelOpened, _setPanelOpened] = useState(true);
  const panelOpenedRef = useRef(panelOpened);

  const [pannelWidth, setPannelWidth] = React.useState(500);
  const header = useRef(null);

  const [roomsAvailable, setRoomsAvailable] = useState([]);

  // Remote CLient Mode
  const photonClientRef = useRef(null);
  const intervalRef = useRef(null);
  const timeRef = useRef<LocalTime>(null);
  const intervalAvailableRoomsRef = useRef(null);
  //

  const FileIcon = ({ data, baseUrlApi }) => {
    return (
      <Grid
        container
        style={{ cursor: 'pointer' }}
        alignItems="center"
        onClick={() => {
          clickFile(`${baseUrlApi}${data.uri}`);
        }}
      >
        <Grid
          item
          style={{
            maxWidth: '54px',
            maxHeight: '54px'
          }}
          className="Events__document"
          dangerouslySetInnerHTML={{ __html: getIcon(data.name).svg }}
        ></Grid>
      </Grid>
    );
  };

  const clickFile = (uri) => {
    window.open(uri, '_blank');
  };

  const onResizeHandler = () => {
    let pannelWidthVar = 0;

    if (mode === 'administrate') {
      if (panelOpenedRef.current) {
        if (window.innerWidth < 600) {
          if (window.innerWidth > window.innerHeight) {
            pannelWidthVar = 300;
          } else {
            pannelWidthVar = 0;
          }
        } else if (window.innerWidth < 900) {
          if (window.innerWidth > window.innerHeight) {
            pannelWidthVar = 300;
          } else {
            pannelWidthVar = 0;
          }
        } else if (window.innerWidth < 1200) {
          pannelWidthVar = 300;
        } else if (window.innerWidth < 1536) {
          pannelWidthVar = 400;
        } else if (window.innerWidth >= 1536) {
          pannelWidthVar = 600;
        }
        // if (context === 'EDL_3D_Tech') {
        setMatterportViewerWidth(`${window.innerWidth - pannelWidthVar}px`);
      } else {
        setMatterportViewerWidth(`${window.innerWidth}px`);
      }
    } else {
      setMatterportViewerWidth(`${window.innerWidth}px`);
    }
    setPannelWidth(pannelWidthVar);
    // if (header !== null && header !== undefined && header.current !== null && header.current !== undefined) {
    // setHeaderHeight(header.current.clientHeight);
    setMainHeight(window.innerHeight);
    // }
    // if (!layoutWithPannel && window.innerWidth > window.innerHeight) {
    //   layoutWithPannel = true;
    // }
  };

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  React.useEffect(() => {
    // window.addEventListener('resize', handleResize, false);

    onResizeHandler();
    window.addEventListener('resize', onResizeHandler);
    // onClickIconHandler(0);

    // console.log('mode', mode);
    if (mode === 'remoteClient') {
      // console.log('REMOTE CLIENT MODE ');
    }
    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);

  const actorJoinOrLeave = (actor: Photon.LoadBalancing.Actor) => {
    // console.log('actorJoinOrLeave', actor);
  };

  const refreshRooms = async () => {
    const rooms = await photonClientRef.current.availableRooms();
    // console.log('rooms', rooms);

    setRoomsAvailable(rooms);
  };

  const connectPhoton = async () => {
    const photonClient = new PhotonClient();
    await photonClient.start();
    photonClientRef.current = photonClient;
    timeRef.current = new LocalTime(photonClient);
    timeRef.current.start();

    const rooms = await photonClientRef.current.availableRooms();

    setRoomsAvailable(rooms);

    if (rooms && rooms.length === 0) {
      intervalAvailableRoomsRef.current = setInterval(refreshRooms, 1000);
    }

    // console.log('product uuid', idProduct);
    // console.log(rooms);
    if (rooms && rooms.length > 0) {
    }
  };

  useEffect(() => {
    roomsAvailable.forEach(async (room) => {
      // console.log('room', room.name);

      if (room.name === idProduct) {
        const roomJoined = await photonClientRef.current.joinRoom(roomsAvailable[0].name);

        // console.log(roomJoined);

        photonClientRef.current.events.on(mpSdk.Sweep.Event.ENTER, pushToEventLog);
        photonClientRef.current.events.on(mpSdk.Sweep.Event.EXIT, pushToEventLog);
        photonClientRef.current.events.on(mpSdk.Mode.Event.CHANGE_START, pushToEventLog);
        photonClientRef.current.events.on(mpSdk.Mode.Event.CHANGE_END, pushToEventLog);

        photonClientRef.current.events.on(onActorPropertiesChangedEvent, (actor: Photon.LoadBalancing.Actor) => {
          const frame: Frame = actor.getCustomProperty('pose');

          mpSdk.Camera.setRotation({ x: frame.pose.rotation.x, y: frame.pose.rotation.y });
        });

        clearInterval(intervalAvailableRoomsRef.current);
        setEnteredInRoom(true);
      }
    });
  }, [roomsAvailable]);

  const pushToEventLog = (event) => {
    // console.log('pushToEventLog', event);
    mpSdk.Sweep.moveTo(event.args[1], { transition: 'transition.fly' });
  };

  useEffect(() => {
    // console.log('mpSdk !!!!!! ', mpSdk);
    if (mpSdk && mode === 'remoteClient') {
      connectPhoton();
    }
  }, [mpSdk]);

  useEffect(() => {
    if (dimensions.width > dimensions.height) {
      setIsPortrait(false);
    } else {
      setIsPortrait(true);
    }
  }, [dimensions]);

  const selectedFiles = [];

  // Params matterport

  const help = 0;
  const dollHouse = mode === 'view3D' || mode === 'remoteClient' ? 1 : 0;
  const mpHl = 0;
  const mpGt = 0;
  const mpHr = 0;
  const mpMls = 0;
  const mpF = 1;
  const mpNozoom = 0;
  const mpWh = 0;
  const mpLang = 'fr';
  const mpSearch = mode === 'view3D' || mode === 'remoteClient' ? 0 : 1;

  useEffect(() => {
    if (idPro) {
      const productType = idPro.split('-')[0] === 'bui' ? 'building' : 'product';
      if (getProductInfos === null) {
        navigate('/declareEvent/' + idPro);
      }
    }
  }, [idPro]);

  useEffect(() => {
    if (mode === 'administrate') {
      getEvent(idEvent).then((eventData) => {
        setIdProduct(eventData.uuid_owner);
        setEventDatas(eventData);
      });
    } else if (mode === 'view3D' || mode === 'remoteClient' || mode === 'declare') {
      setIdProduct(idProduct);
    }
  }, [idEvent, idProduct, mode]);

  useEffect(() => {
    if (idPro !== undefined && idPro !== null && idPro !== '') {
      const productType = idPro.split('-')[0] === 'bui' ? 'building' : 'product';
      if (mode === 'view3D' || mode === 'remoteClient' || mode === 'administrate') {
        getProduct(idPro, productType).then((productData) => {
          setProductDatas(productData);
          axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + productData.jwt;
          setIdModel(productData.property.matterport_scan.model_id);
        });
      } else if (mode === 'declare') {
        setIdModel(getProductInfos.matterport_scan.model_id);
      }
    }
  }, [idPro, getProductInfos, mode]);

  useEffect(() => {
    if (MattertagsList.length > 0 && filesTags.length !== MattertagsList.length) setOpen(true);
  }, [MattertagsList]);

  const sendEmails = (eventData, hasTags) => {
    const productType = idProduct.split('-')[0] === 'bui' ? 'building' : 'product';
    SendMailToClient(eventData).then(() => {
      SendMailToGest(eventData, getEmailHandlers, hasTags).then(() => {
        setOpen(false);
        navigate(`/validateEvent/${idProduct}`);
      });
    });
  };

  const submitTagsHandler = (e) => {
    if (MattertagsList.length > 0) {
      setTags(MattertagsList);
      setTagsAssociatedFiles(filesTags);

      postEvent(getEventPayload()).then(async (eventData: any) => {
        eventData.address = getProductInfos.address;

        if (getProductInfos.matterport_scan !== undefined && MattertagsList.length > 0) {
          const tags = JSON.parse(JSON.stringify(MattertagsList));
          const filesSendedPromises = [];
          tags.forEach((tagItem) => {
            tagItem.uuid_organization = getOrganization().uuid;
            tagItem.uuid_owner = eventData.uuid;
            submitTags([tagItem]).then((tags) => {
              filesTags.forEach((filesGroup) => {
                if (filesGroup.sid === tagItem.sid) {
                  filesGroup.files[0].forEach((file) => {
                    const body = new FormData();
                    body.append('file', file);
                    body.append('uuid_organization', getOrganization().uuid);
                    body.append('uuid_owner', tags[0].uuid);
                    body.append('owner_kind', 'tag');

                    //.then((file) => {
                    filesSendedPromises.push(createFile(body));
                  });
                }
              });
            });
          });
          await Promise.all(filesSendedPromises)
            .then((respArr: any) => {
              sendEmails(eventData, true);
            })
            .catch((err) => {
              console.log(err);
            });

          const results = await Promise.all(filesSendedPromises.map((p) => p.catch((e) => e)));
          const validResults = results.filter((result) => !(result instanceof Error));
        }
      });
    } else {
      navigate('/documentEvent/' + idProduct);
    }
  };

  let file = null;

  const setSelectedFile = (selectedFiles) => {
    setFiles(selectedFiles);
  };

  const onNewTag = (newTag) => {};

  const validateFiles = () => {
    setFilesTags([...filesTags, { sid: MattertagsList[MattertagsList.length - 1].sid, files: [files] }]);
    setFiles([]);
  };

  const handleNavigateToTag = (tag) => {
    const tagToNavigate = MattertagsList.find((tagItem) => tagItem.sid === tag);
    setTagToNavigate(tag);
    setTimeout(() => {
      setTagToNavigate(null);
    }, 500);
  };

  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} invisible>
        <CircularProgress color="inherit" />
      </Backdrop>
      {mode === 'remoteClient' && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0px',
            left: '0px',
            zIndex: 99999999999,
            backgroundColor: 'rgb(0, 0, 0, .0)'
          }}
        >
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            <h1 style={{ color: 'white' }}>Mode visite pilotée</h1>
            <br />
            {!enteredInRoom && (
              <div style={{ color: 'white' }}>En attente de connection de l'administrateur de la visite ...</div>
            )}

            {/* {roomsAvailable.map((room) => {
              return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    onClick={() => {
                      photonClientRef.current.joinRoom(room.name);
                    }}
                  >
                    {room.name}
                  </button>
                </div>
              );
            })} */}

            <div></div>
          </div>
        </div>
      )}
      {idModel && (
        <>
          <Box
            id="matterportContainer"
            component="main"
            sx={
              layoutWithPannel ||
              window.location.pathname.includes('tagEvent') ||
              mode === 'view3D' ||
              mode === 'remoteClient'
                ? {
                    width: matterportViewerWidth,
                    position: 'absolute',
                    top: `${headerHeight}px`,
                    height: `${mainHeight}px`,
                    paddingLeft: 0,
                    paddingRight: 0,
                    backgroundColor: '#1C1C1E',
                    overflow: 'hidden',
                    margin: 0
                  }
                : {
                    width: `calc(100%)`,
                    position: 'absolute',
                    top: `${40}px`,
                    height: panelOpened ? `calc(50% - 40px)` : `calc(100% - 80px)`,
                    paddingLeft: 0,
                    paddingRight: 0,
                    backgroundColor: '#1C1C1E',
                    overflow: 'hidden'
                  }
            }
          >
            <MatterPort
              mode={mode}
              modelID={idModel}
              mpHelp={help}
              dollHouse={dollHouse}
              mpHl={mpHl}
              mpGt={mpGt}
              mpHr={mpHr}
              mpQs={mode === 'remoteClient' ? 1 : 0}
              mpMls={mpMls}
              mpF={mpF}
              mpNozoom={mpNozoom}
              mpWh={mpWh}
              mpSearch={mpSearch}
              mpLang={mpLang}
              matterTagsList={setMattertagsList}
              getMatterTagList={MattertagsList}
              id_event={idEvent}
              eventDatas={eventDatas}
              setLoading={setLoading}
              idProduct={idProduct}
              onNewTag={onNewTag}
              getEventPayload={getEventPayload}
              setOpen={setOpen}
              getFilesTags={filesTags}
              setSceneReadyParent={setSceneReady}
              dimensions={dimensions}
              tagToNavigate={tagToNavigate}
              setMpSdk={setMpSdk}
            />
          </Box>
          <Box
            ref={boxRef}
            sx={
              layoutWithPannel ||
              window.location.pathname.includes('tagEvent') ||
              mode === 'view3D' ||
              mode === 'remoteClient'
                ? {
                    right: 0,
                    width: `${pannelWidth}px`,
                    position: 'absolute',
                    top: `${headerHeight}px`,
                    overflow: 'hidden',
                    height: `${mainHeight}px`
                  }
                : {
                    right: 0,
                    width: `100%`,
                    position: 'absolute',
                    bottom: `${50}px`,
                    overflow: 'hidden',
                    height: !panelOpened ? '0px' : `calc(50% - 50px)`
                  }
            }
          >
            <div style={{ backgroundColor: 'transparent', maxHeight: '100vh', padding: '10px', overflow: 'scroll' }}>
              <h2>
                Evènement saisi au{' '}
                {`${productDatas?.property?.address?.street} ${productDatas?.property?.address?.zip_code} ${productDatas?.property?.address?.city}`}{' '}
              </h2>

              {eventDatas?.data?.contact_requestor && (
                <>
                  <h3>Déclarant</h3>
                  {eventDatas &&
                    eventDatas?.data?.contact_requestor?.name &&
                    eventDatas?.data?.contact_requestor?.name !== '' && (
                      <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                        Déclarant :{' '}
                        <span style={{ fontSize: 16, color: '#171717' }}>
                          {eventDatas?.data?.contact_requestor?.name}
                        </span>
                      </Typography>
                      // <div>nom : {eventDatas.data.contact_requestor.name}</div>
                    )}
                  {eventDatas &&
                    eventDatas?.data?.contact_requestor?.email &&
                    eventDatas?.data?.contact_requestor?.email !== '' && (
                      <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                        Email :{' '}
                        <span style={{ fontSize: 16, color: '#171717' }}>
                          {eventDatas?.data?.contact_requestor?.email}
                        </span>
                      </Typography>
                    )}

                  {eventDatas &&
                    eventDatas?.data?.contact_requestor?.phone &&
                    eventDatas?.data?.contact_requestor?.phone !== '' && (
                      <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                        Téléphone :{' '}
                        <span style={{ fontSize: 16, color: '#171717' }}>
                          {eventDatas?.data?.contact_requestor?.phone}
                        </span>
                      </Typography>
                    )}
                </>
              )}

              <h3>Evènement</h3>

              {eventDatas && eventDatas.kind !== 0 && referentielEventTypes[eventDatas.kind] && (
                <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                  Type d'évènement :{' '}
                  <span style={{ fontSize: 16, color: '#171717' }}>{referentielEventTypes[eventDatas.kind]}</span>
                </Typography>
              )}

              {eventDatas && eventDatas.desc && eventDatas.desc !== '' && (
                <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                  Description : <span style={{ fontSize: 16, color: '#171717' }}>{eventDatas.desc}</span>
                </Typography>
              )}

              {eventDatas && eventDatas.tags && eventDatas.tags.length > 0 && (
                <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                  Localisation :{' '}
                  <svg
                    style={{ marginLeft: 5, cursor: 'pointer' }}
                    onClick={() => {
                      handleNavigateToTag(eventDatas.tags[0]);
                    }}
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.1898 20.9815L14.5 28.6713L6.81017 20.9815C5.28928 19.4606 4.25355 17.5229 3.83395 15.4133C3.41435 13.3038 3.62972 11.1172 4.45282 9.13005C5.27593 7.14291 6.66981 5.44447 8.45819 4.24952C10.2466 3.05457 12.3491 2.41676 14.5 2.41676C16.6509 2.41676 18.7534 3.05457 20.5418 4.24952C22.3302 5.44447 23.7241 7.14291 24.5472 9.13005C25.3703 11.1172 25.5857 13.3038 25.1661 15.4133C24.7464 17.5229 23.7107 19.4606 22.1898 20.9815ZM13.2917 12.0833H9.66667V14.5H13.2917V18.125H15.7083V14.5H19.3333V12.0833H15.7083V8.45833H13.2917V12.0833Z"
                      fill="#F17633"
                    />
                  </svg>
                </Typography>
              )}

              {eventDatas?.files && eventDatas?.files.length > 0 && (
                <>
                  <h3>Images</h3>

                  {eventDatas && (
                    <>
                      <h5>Images de l'évènement :</h5>
                      {eventDatas.files.map((file) => {
                        if (file.kind === 'image') {
                          return (
                            <img
                              style={{
                                maxWidth: '200px',
                                maxHeight: '200px',
                                border: '1px solid black',
                                cursor: 'pointer'
                              }}
                              src={process.env.REACT_APP_URL_MEDIAS + file.uri}
                              onClick={() => {
                                window.open(process.env.REACT_APP_URL_MEDIAS + file.uri, '_blank');
                              }}
                            ></img>
                          );
                        } else {
                          return FileIcon({ data: file, baseUrlApi: process.env.REACT_APP_URL_MEDIAS });
                        }
                      })}
                    </>
                  )}

                  {eventDatas && eventDatas?.tags && eventDatas?.tags.length > 0 && (
                    <>
                      {eventDatas?.tags?.map((tag, index) => {
                        tag?.images?.map((file) => {
                          return (
                            <>
                              {index === 0 && <h5>Images des tags : </h5>}
                              <img
                                style={{
                                  maxWidth: '200px',
                                  maxHeight: '200px',
                                  border: '1px solid black',
                                  cursor: 'pointer'
                                }}
                                src={process.env.REACT_APP_URL_MEDIAS + file.uri}
                                onClick={() => {
                                  window.open(process.env.REACT_APP_URL_MEDIAS + file.uri, '_blank');
                                }}
                              ></img>
                            </>
                          );
                        });
                      })}
                    </>
                  )}
                </>
              )}
            </div>
          </Box>
        </>
      )}
      {sceneReady && loading === false && mode !== 'administrate' && mode !== 'view3D' && mode !== 'remoteClient' && (
        <div
          style={{
            position: 'absolute',
            left: 'calc(50% +  0px)',
            bottom: isPortrait ? '88px' : '20px'
          }}
        >
          <SvgIcon
            onClick={submitTagsHandler}
            sx={{
              fontSize: 32,
              color: '#F17633',
              backgroundColor: 'white',
              borderRadius: '30px',
              padding: '0',
              border: 0,
              margin: '0',
              '&:hover': {
                cursor: 'pointer',
                color: 'white',
                backgroundColor: '#F17633'
              }
            }}
          >
            <ArrowCircleRightIcon sx={{ fontSize: 32 }} />
          </SvgIcon>
          {/* <button onClick={submitTagsHandler}>
            <ArrowCircleRightIcon sx={{ fontSize: 32 }} />
          </button> */}
        </div>
      )}
      <Dialog open={open}>
        <UploadFile setFile={setSelectedFile} />
        <Button
          onClick={() => {
            setOpen(false);
            setFiles([]);
            // setFilesTags([...filesTags, [files]]);
          }}
        >
          Ne pas associer de fichiers
        </Button>
        {files.length > 0 && (
          <Button
            onClick={() => {
              setOpen(false);
              validateFiles();
            }}
          >
            Valider les fichiers associés
          </Button>
        )}
      </Dialog>
    </div>
  );
};

export default View3D;

import { Typography, Grid, Rating, Button, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { ViewerIcon } from './ViewerIcon';
import _ from 'lodash';
import { ImageComponent } from './ImageComponent';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
const { REACT_APP_URL_MEDIAS } = process.env;

export const PanelContent = ({
  activePanel,
  propertyData,
  layoutWithPannel = null,
  context,
  selectedEdl,
  selectedOdm,
  referencial,
  referencialEquipment,
  datas,
  selection,
  setSelection,
  handleZoomImage,
  panelData
}) => {
  const theme = useTheme();
  const [subCategories, setSubCategories] = useState<any>([]);
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));
  const [equipmentsBySubCategories, setEquipmentsBySubCategories] = useState<any>({});
  const [equipmentsList, setEquipmentsList] = useState<any>([]);
  dayjs.locale('fr-fr');
  useEffect(() => {
    console.log('selectedEdl', selectedEdl);
    console.log('referencialEquipment', referencialEquipment);
  }, []);

  useEffect(() => {
    if (subCategories.length > 0) {
      if (_.indexOf(subCategories, selection.selectSubCategory) !== -1) {
        console.log('scrolling to', selection.selectSubCategory);
        document
          .getElementById(`button-${_.indexOf(subCategories, selection.selectSubCategory)}`)
          ?.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
      }
    }
  }, [subCategories]);

  const drawImages = (equipment, indexEquipment) => {
    let totalTagedImages = 0;

    equipment?.states[0]?.tags &&
      equipment?.states[0]?.tags.forEach((tag) => {
        if (tag.images) totalTagedImages += tag.images.length;
      });
    const nonTagedImages = equipment?.states[0]?.images?.length || 0;
    const totalImages = totalTagedImages + nonTagedImages;

    const reactElements = [];

    equipment?.states[0]?.tags?.map((tag, indexTag) => {
      tag?.images &&
        tag?.images?.map((image, indexImage) => {
          reactElements.push(
            <Grid
              key={`${indexTag}-${indexImage}`}
              item
              xs={
                layoutWithPannel
                  ? 12
                  : totalImages > 1
                    ? indexTag === totalImages - 1 && indexTag % 2 === 0
                      ? 12
                      : 6
                    : 12
              }
              xl={totalImages > 1 ? (indexTag === totalImages - 1 && indexTag % 2 === 0 ? 12 : 6) : 12}
            >
              <ImageComponent
                isLocalized={true}
                index={{ indexTag, indexEquipment }}
                imageUri={image.uri}
                selection={selection}
                setSelection={setSelection}
                handleZoomImage={handleZoomImage}
                layoutWithPannel={layoutWithPannel}
              />
            </Grid>
          );
        });
    });

    return reactElements;
  };

  useEffect(() => {
    console.log(selection);
    console.log(subCategories);
    console.log(_.indexOf(subCategories, selection.selectSubCategory));

    if (selection.selectEquipment !== null && selection.selectedImage !== null) {
      setTimeout(() => {
        document.getElementById('PanelContent')?.scrollTo({
          top: document.getElementById(`${selection.selectEquipment}-${selection.selectedImage}`)
            ? document.getElementById(`${selection.selectEquipment}-${selection.selectedImage}`).offsetTop
            : 0,
          behavior: 'smooth'
        });
        if (subCategories) {
          if (_.indexOf(subCategories, selection.selectSubCategory) !== -1) {
            console.log('scrolling to', selection.selectSubCategory);
            document
              .getElementById(`button-${_.indexOf(subCategories, selection.selectSubCategory)}`)
              ?.scrollIntoView({ block: 'end' });
          }
        }
      }, 300);
    } else {
      document.getElementById('title')?.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('PanelContent')?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

    if (panelData.hasSub && equipmentsBySubCategories && selection) {
      setEquipmentsList(equipmentsBySubCategories[selection.selectSubCategory]);
    }
  }, [selection]);

  useEffect(() => {
    if (panelData.hasSub) {
      let equipmentsBySubCategories = {};
      if (activePanel === 6) {
        datas[panelData.name].forEach((equipment) => {
          const catName = _.find(referencialEquipment['inventoryEquipmentKinds'], {
            id: Number(equipment.sub_tab)
          }).name;
          if (equipmentsBySubCategories[catName]) {
            equipmentsBySubCategories[catName].push(equipment);
          } else {
            equipmentsBySubCategories[catName] = [equipment];
          }
        });
      } else {
        equipmentsBySubCategories =
          activePanel === 5 ? _.groupBy(datas.degradations, 'category') : _.groupBy(datas[panelData.name], 'sub_tab');
      }

      const tmpSubCategories = [];
      Object.keys(equipmentsBySubCategories).forEach((key) => {
        tmpSubCategories.push(activePanel === 5 || activePanel === 6 ? key : key);
      });
      setSubCategories(tmpSubCategories);

      setEquipmentsBySubCategories(equipmentsBySubCategories);
      setEquipmentsList(equipmentsBySubCategories[selection.selectSubCategory]);
    } else if (panelData.name !== 'generalState' && panelData.name !== 'informations') {
      setEquipmentsList(datas[panelData.name]);
    }
  }, [activePanel]);

  useEffect(() => {
    console.log('equipmentsList', equipmentsList);
  }, [equipmentsList]);

  return (
    <div>
      <Grid
        id={'title'}
        container
        spacing={1}
        sx={{ ml: '0vh', mr: '0vh', mt: layoutWithPannel ? '1vh' : 'Ovh', pl: '1vh', pr: '0vh' }}
      >
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography
            variant="h4"
            style={{ color: '#062746' }}
            sx={{ pr: '2vh', lineHeight: matchesSm ? '16px' : '13px', fontSize: '20px' }}
          >
            <ViewerIcon
              key={0}
              isActive={activePanel}
              iconId={activePanel}
              iconName={panelData.label}
              setActivePanel={null}
              layoutWithPannel={layoutWithPannel}
              context={context}
              titleIcon={true}
            />{' '}
            {panelData.label.toUpperCase()}
          </Typography>
        </Grid>
      </Grid>

      {panelData.hasSub && (
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            overflowX: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            // pl: 'calc(env(safe-area-inset-left) - 20px)',
            // pr: 'calc(env(safe-area-inset-right) - 20px)',
            width: 'auto',
            ml: '1vw',
            mr: '1vw'
          }}
          // style={{ background: 'linear-gradient(90deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 4%)' }}
        >
          {/* <div
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              width: '10px',
              zIndex: 9999999999999,
              height: '52px',
              right: '3px',
              boxShadow: 'rgb(0, 0, 0) -5px 0px 9px -4px'
            }}
          ></div>
          <div
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              width: '10px',
              zIndex: 9999999999999,
              height: '32px',
              left: '3px',
              boxShadow: 'rgb(0, 0, 0) 5px 0px 9px -4px'
            }}
          ></div> */}
          <div
            style={{
              display: 'flex'
            }}
          >
            {subCategories.length > 0 &&
              subCategories.map((key, index) => {
                return (
                  <Button
                    id={'button-' + index}
                    key={index}
                    variant={selection.selectSubCategory === key ? 'contained' : 'outlined'}
                    color="primary"
                    sx={{
                      fontWeight: 'bold',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      mr: index < subCategories.length - 1 ? '1vw' : '0',
                      mb: '0vh'
                      // height: '50px',
                      // padding: '0 5vw',
                      // margin: '0 0vw'
                    }}
                    size={'small'}
                    onClick={(e) => {
                      e.currentTarget.scrollIntoView({ block: 'end', inline: 'nearest' });
                      setSelection((prev) => ({
                        ...prev,
                        selectCategory: panelData.name,
                        selectSubCategory: activePanel === 5 || activePanel === 6 ? key : key,
                        selectEquipment: null,
                        selectedImage: null
                      }));
                    }}
                  >
                    {activePanel === 5 || activePanel === 6
                      ? key
                      : _.find(referencialEquipment.equipmentCategoryKinds, { id: Number(key) })
                        ? _.find(referencialEquipment.equipmentCategoryKinds, { id: Number(key) }).name
                        : null}
                  </Button>
                );
              })}
          </div>
          {/* <div
          id={'sub-categoriies-container'}
          style={{
            display: 'flex',
            overflow: 'scroll',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            marginLeft: '1vw',
            marginRight: '2vh'
          }}
        > */}
        </Grid>
      )}

      <Grid
        id={'PanelContent'}
        item
        xs={12}
        sx={{ ml: '1vh', mr: '3vh', mb: '2vh', mt: '1vh', pr: '2vh' }}
        style={{
          overflow: 'scroll',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          height: '100%',
          width: '100%',
          position: 'absolute',
          paddingBottom: '165px'
        }}
      >
        {/* Content with images */}

        {equipmentsList &&
          equipmentsList.length > 0 &&
          panelData.name !== 'informations' &&
          panelData.name !== 'generalState' &&
          equipmentsList?.length > 0 &&
          equipmentsList?.map((equipment, indexEquipment) => {
            console.log(equipment);
            return (
              <Grid item xs={12} key={indexEquipment}>
                <>
                  {/* Equipments */}
                  {equipment && equipment?.states && equipment.kind !== 'document' && (
                    <>
                      <Typography
                        variant="h5"
                        sx={{ fontSize: matchesSm ? '20px' : '20px', fontWeight: 'bold', color: '#062746' }}
                      >
                        {equipment.title &&
                        equipment.title !== null &&
                        equipment.title !== undefined &&
                        equipment.title !== ''
                          ? equipment.title
                          : referencialEquipment &&
                              _.find(referencialEquipment['equipmentsKind'], { id: equipment?.kind }) &&
                              _.find(referencialEquipment['equipmentsKind'], { id: equipment?.kind }).name
                            ? `${_.find(referencialEquipment['equipmentsKind'], { id: equipment?.kind }).name}`
                            : equipment?.kind}
                      </Typography>
                      {equipment?.states && equipment?.states[0].rating !== 0 && (
                        <>
                          <Rating
                            name="read-only"
                            value={equipment?.states[0].rating}
                            size={matchesSm ? 'medium' : 'small'}
                            readOnly
                          />
                          <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                            Etat :{' '}
                            <span style={{ fontSize: 16, color: '#171717' }}>
                              {_.find(referencialEquipment.equipmentRating, { id: equipment?.states[0].rating }).name}
                            </span>
                          </Typography>
                        </>
                      )}
                      {equipment?.states && equipment?.states[0].state > 0 && (
                        <>
                          <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                            Fonctionnement :{' '}
                            <span style={{ fontSize: 16, color: '#171717' }}>
                              {_.find(referencialEquipment.equipmentStatus, { id: equipment?.states[0].state }).name}
                            </span>
                          </Typography>
                        </>
                      )}
                      {equipment?.states && equipment?.states[0].comment && equipment?.states[0].comment !== '' && (
                        <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                          Observations :{' '}
                          <span style={{ fontSize: 16, color: '#171717' }}>{equipment?.states[0].comment}</span>
                        </Typography>
                      )}

                      {equipment?.states &&
                        equipment?.states[0].damages &&
                        equipment?.states[0].damages.damages.length > 0 && (
                          <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                            Dégradation :{' '}
                            {equipment?.states[0].damages.damages.map((damage, indexDamage) => {
                              return (
                                <span key={indexDamage} style={{ fontSize: 16, color: '#171717' }}>
                                  {`${damage.number} ${damage.name}${damage.number > 1 ? 's' : ''}`}
                                </span>
                              );
                            })}
                          </Typography>
                        )}
                      {equipment?.states && equipment?.states[0].data?.serialNumber && (
                        <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                          N° de série :{' '}
                          <span style={{ fontSize: 16, color: '#171717' }}>
                            {equipment?.states[0].data.serialNumber}
                          </span>
                        </Typography>
                      )}
                      {equipment?.states && equipment?.states[0].data?.meterValue && (
                        <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                          Valeur :{' '}
                          <span
                            style={{ fontSize: 16, color: '#171717' }}
                          >{`${equipment?.states[0].data.meterValue} ${equipment?.states[0].data.meterUnity}`}</span>
                        </Typography>
                      )}

                      {equipment?.states && equipment?.states[0].data?.keyNumber && (
                        <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                          Nombre de clé :{' '}
                          <span
                            style={{ fontSize: 16, color: '#171717' }}
                          >{`${equipment?.states[0].data?.keyNumber}`}</span>
                        </Typography>
                      )}

                      {equipment?.states && equipment?.states[0].data?.inventoryNumber && (
                        <Typography variant="body1" style={{ fontSize: 12, color: '#929292' }}>
                          Nombre :{' '}
                          <span
                            style={{ fontSize: 16, color: '#171717' }}
                          >{`${equipment?.states[0].data?.inventoryNumber}`}</span>
                        </Typography>
                      )}

                      <Grid container spacing={0} style={{ textAlign: 'end', height: '100%' }}>
                        {drawImages(equipment, indexEquipment)}

                        {equipment?.states &&
                          equipment?.states[0]?.images?.map((image, indexImage) => {
                            // const totalTagedImages =
                            //   equipment?.states[0]?.tags.map((tag) => tag.images.length).reduce((a, b) => a + b, 0) || 0;
                            const totalTagedImages = equipment?.states[0]?.tags
                              ? [equipment?.states[0]?.tags[equipment?.states[0]?.tags?.length - 1]]
                                  .map((tag) => tag.images && tag.images.length)
                                  .reduce((a, b) => a + b, 0) || 0
                              : 0;
                            const nonTagedImages = equipment?.states[0]?.images?.length || 0;
                            const totalImages = totalTagedImages + nonTagedImages;
                            return (
                              <Grid
                                key={indexImage}
                                item
                                xs={layoutWithPannel ? 12 : totalImages > 1 ? 6 : 12}
                                xl={totalImages > 1 ? 6 : 12}
                              >
                                <ImageComponent
                                  isLocalized={false}
                                  index={indexImage}
                                  imageUri={image.uri}
                                  selection={selection}
                                  setSelection={setSelection}
                                  handleZoomImage={handleZoomImage}
                                  layoutWithPannel={layoutWithPannel}
                                />
                              </Grid>
                            );
                          })}
                      </Grid>
                    </>
                  )}

                  {/* Annexes */}
                  {equipment.kind === 'document' && (
                    <Grid container spacing={1}>
                      <Grid item xs={12} key={indexEquipment}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h5" sx={{ fontSize: '20px', fontWeight: 'bold', color: '#062746' }}>
                              {equipment.data.title}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {equipment.category && equipment.category !== '' && (
                              <Typography
                                variant="body1"
                                style={{ fontSize: 12, paddingLeft: '0px', color: '#929292' }}
                              >
                                Catégorie :{' '}
                                <span style={{ fontSize: 16, color: '#171717' }}>{`${equipment.category}`}</span>
                              </Typography>
                            )}

                            {equipment.data.description && equipment.data.description !== '' && (
                              <Typography
                                variant="body1"
                                style={{ fontSize: 12, paddingLeft: '0px', color: '#929292' }}
                              >
                                Description :{' '}
                                <span
                                  style={{ fontSize: 16, color: '#171717' }}
                                >{`${equipment.data.description}`}</span>
                              </Typography>
                            )}
                          </Grid>

                          <Grid
                            id={'imageContainer'}
                            container
                            spacing={0}
                            sx={{ pl: '.3vh' }}
                            style={{ textAlign: 'end', height: '100%' }}
                          >
                            <Grid item xs={12}>
                              <ImageComponent
                                isLocalized={false}
                                index={indexEquipment}
                                imageUri={equipment.uri}
                                selection={selection}
                                setSelection={null}
                                handleZoomImage={handleZoomImage}
                                layoutWithPannel={layoutWithPannel}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              </Grid>
            );
          })}

        {/* Informations */}
        {panelData.name === 'informations' && (
          <Grid container>
            <Grid item xs={layoutWithPannel || window.innerWidth < 600 ? 12 : 6} sx={{}}>
              <Grid item xs={12} sx={{}}>
                <svg
                  style={{ position: 'relative', top: '5px', right: '0px', paddingRight: '5px', cursor: 'pointer' }}
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    window.open(
                      `${REACT_APP_URL_MEDIAS}${_.find(selectedEdl.files, { category: 'Etats des lieux' }).uri}`,
                      '_blank'
                    );
                  }}
                >
                  <path
                    fill="#062746"
                    d="M32.0453 9.50654L26.3646 3.93615C25.7552 3.33861 24.9292 3 24.0693 3H10.25C8.45573 3.00664 7 4.43409 7 6.19352V33.8131C7 35.5725 8.45573 37 10.25 37H29.75C31.5443 37 33 35.5725 33 33.8131V11.7639C33 10.9207 32.6547 10.1041 32.0453 9.50654ZM29.4859 11.505H24.3333V6.45245L29.4859 11.505ZM10.25 33.8131V6.19352H21.0833V13.0984C21.0833 13.9815 21.8078 14.6919 22.7083 14.6919H29.75V33.8131H10.25ZM27.1906 24.2724C26.3646 23.4757 24.0083 23.6948 22.8302 23.8409C21.6656 23.1437 20.887 22.181 20.3385 20.7668C20.6026 19.6979 21.0224 18.0713 20.7042 17.0488C20.4198 15.3093 18.1448 15.4819 17.8198 16.6571C17.5219 17.726 17.7927 19.2132 18.2938 21.1121C17.6167 22.6989 16.6078 24.8301 15.8969 26.0518C14.5427 26.7356 12.7146 27.7913 12.4438 29.1191C12.2203 30.1681 14.2042 32.784 17.5964 27.0476C19.113 26.5563 20.7651 25.9522 22.2276 25.7131C23.5073 26.3904 25.0036 26.8418 26.0057 26.8418C27.7323 26.8418 27.9016 24.9695 27.1906 24.2724ZM13.7776 29.4378C14.1229 28.5282 15.4365 27.4792 15.8359 27.114C14.5495 29.1258 13.7776 29.4843 13.7776 29.4378ZM19.3026 16.7832C19.8036 16.7832 19.7563 18.9145 19.4245 19.4921C19.1266 18.5692 19.1333 16.7832 19.3026 16.7832ZM17.6505 25.8526C18.3073 24.7305 18.8693 23.396 19.3229 22.2209C19.8849 23.2234 20.6026 24.0268 21.3609 24.5778C19.9526 24.8633 18.7271 25.4476 17.6505 25.8526ZM26.5609 25.5206C26.5609 25.5206 26.2224 25.919 24.0354 25.0027C26.412 24.8301 26.8047 25.3613 26.5609 25.5206Z"
                  />
                </svg>{' '}
                <span style={{ fontSize: '20px', bottom: '5px' }}>
                  <a
                    style={{ color: '#131313', textDecoration: 'underline' }}
                    href={`${REACT_APP_URL_MEDIAS}${_.find(selectedEdl.files, { category: 'Etats des lieux' }).uri}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    EDL PDF
                  </a>
                </span>
              </Grid>
            </Grid>
            <Grid item xs={layoutWithPannel || window.innerWidth < 600 ? 12 : 6} sx={{}}>
              <Grid item xs={12}>
                <svg
                  style={{ position: 'relative', top: '5px', right: '0px', paddingRight: '5px' }}
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.3333 3V6.33333H33.345C34.2583 6.33333 35 7.075 35 7.98833V34.6783C35 35.5917 34.2583 36.3333 33.345 36.3333H6.655C5.74167 36.3333 5 35.5917 5 34.6783V7.98833C5 7.075 5.74167 6.33333 6.655 6.33333H11.6667V3H28.3333ZM11.6667 9.66667H8.33333V33H31.6667V9.66667H28.3333V13H11.6667V9.66667ZM15 26.3333V29.6667H11.6667V26.3333H15ZM15 21.3333V24.6667H11.6667V21.3333H15ZM15 16.3333V19.6667H11.6667V16.3333H15ZM25 6.33333H15V9.66667H25V6.33333Z"
                    fill="#062746"
                  />
                  <path
                    d="M27.9797 26.5889L29 26.5889L29 30.552L27.9797 30.552L27.9797 26.5889ZM21.5352 30.188L29 30.188L29 31.4749L21.5352 31.4749L21.5352 30.188ZM24.6523 27.1067L25.6572 27.1067L25.6572 30.552L24.6523 30.552L24.6523 27.1067ZM21.5352 26.6145L22.5605 26.6145L22.5605 30.552L21.5352 30.552L21.5352 26.6145ZM29 23.3691L29 24.9636L27.9797 24.9534L27.9797 23.3691C27.9797 22.9077 27.8789 22.5215 27.6772 22.2104C27.4756 21.896 27.1868 21.6602 26.8108 21.5029C26.4348 21.3423 25.9871 21.262 25.4675 21.262L25.0625 21.262C24.6592 21.262 24.302 21.3081 23.991 21.4004C23.6799 21.4893 23.4185 21.6226 23.2065 21.8003C22.9946 21.9746 22.834 22.1899 22.7246 22.4463C22.6152 22.6992 22.5605 22.9915 22.5605 23.323L22.5605 24.9944L21.5352 24.9944L21.5352 23.323C21.5352 22.8274 21.6189 22.3745 21.7864 21.9644C21.9504 21.5542 22.188 21.2004 22.499 20.9031C22.8101 20.6023 23.1826 20.3716 23.6167 20.2109C24.0508 20.0503 24.5361 19.97 25.0728 19.97L25.4675 19.97C26.0041 19.97 26.4895 20.0503 26.9236 20.2109C27.3577 20.3716 27.7302 20.6023 28.0413 20.9031C28.3489 21.2039 28.5864 21.5627 28.7539 21.9797C28.918 22.3933 29 22.8564 29 23.3691ZM21.5352 24.251L29 24.251L29 25.5378L21.5352 25.5378L21.5352 24.251ZM27.9797 14.0022L29 14.0022L29 17.75L27.9797 17.75L27.9797 14.0022ZM21.5352 17.3911L29 17.3911L29 18.678L21.5352 18.678L21.5352 17.3911Z"
                    fill="#062746"
                  />
                </svg>

                <span style={{ fontSize: '20px', bottom: '5px' }}>Détails de la mission</span>
              </Grid>

              <Grid item xs={12}>
                {selectedOdm?.ref_internal && (
                  <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                    N° de dossier :{' '}
                    <span style={{ fontSize: 16, color: '#171717' }}>{`${selectedOdm?.ref_internal}`}</span>
                  </Typography>
                )}

                {_.find(referencial['edl.kind'], { id: selectedEdl.kind.toString() }) && (
                  <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                    Type d'EDL :{' '}
                    <span
                      style={{ fontSize: 16, color: '#171717' }}
                    >{`${_.find(referencial['edl.kind'], { id: selectedEdl.kind.toString() }).name}`}</span>
                  </Typography>
                )}

                {selectedOdm?.appointment_date && (
                  <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                    Date et heure du RDV :{' '}
                    <span
                      style={{ fontSize: 16, color: '#171717' }}
                    >{`${dayjs(selectedOdm?.appointment_date).format('DD/MM/YYYY')} - ${dayjs(selectedOdm?.appointment_date).format('HH')}h${dayjs(selectedOdm?.appointment_date).format('mm')}`}</span>
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* /// */}
            <Grid item xs={layoutWithPannel || window.innerWidth < 600 ? 12 : 6} sx={{}}>
              <Grid item xs={12}>
                <svg
                  style={{ position: 'relative', top: '5px', right: '0px', paddingRight: '5px' }}
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M35.1111 37H4.88889C3.84568 37 3 36.1543 3 35.1111V21.3222C3 20.7781 3.23483 20.2604 3.64411 19.9018L6.77778 17.1667V4.88889C6.77778 3.84568 7.62346 3 8.66667 3H35.1111C36.1543 3 37 3.84568 37 4.88889V35.1111C37 36.1543 36.1543 37 35.1111 37ZM14.3333 11.177C14.7907 11.1775 15.2323 11.344 15.5762 11.6454L25.0207 19.9093C25.4287 20.2658 25.6639 20.7804 25.6667 21.3222V33.2222H33.2222V6.77778H10.5556V13.8611L13.0886 11.6436C13.4332 11.3423 13.8756 11.1766 14.3333 11.177ZM12.4444 27.5556H16.2222V33.2222H21.8889V22.1854L14.3333 15.5743L6.77778 22.1854V33.2222H12.4444V27.5556Z"
                    fill="#062746"
                  />
                </svg>
                <span style={{ fontSize: '20px', bottom: '5px' }}>Description du bien</span>
              </Grid>
              <Grid item xs={12}>
                {propertyData?.address?.street && propertyData?.address?.zip_code && propertyData?.address?.city && (
                  <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                    Adresse :{' '}
                    <span
                      style={{ fontSize: 16, color: '#171717' }}
                    >{`${propertyData?.address?.street}, ${propertyData?.address?.zip_code} ${propertyData?.address?.city}`}</span>
                  </Typography>
                )}

                {propertyData?.address_complement && (
                  <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                    Localisation :{' '}
                    <span style={{ fontSize: 16, color: '#171717' }}>{`${propertyData?.address_complement}`}</span>
                  </Typography>
                )}

                {_.find(referencial['product.kind'], { id: propertyData.kind.toString() }) && (
                  <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                    Type de bien :{' '}
                    <span
                      style={{ fontSize: 16, color: '#171717' }}
                    >{`${_.find(referencial['product.kind'], { id: propertyData.kind.toString() }).name}`}</span>
                  </Typography>
                )}

                {selectedOdm?.data?.property?.data?.logement_type !== undefined &&
                  selectedOdm?.data?.property?.data?.logement_type !== null && (
                    <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                      Type de logement :{' '}
                      <span
                        style={{ fontSize: 16, color: '#171717' }}
                      >{`${referencial['surface.kind'][selectedOdm?.data?.property.data.logement_type]}`}</span>
                    </Typography>
                  )}

                {selectedOdm.data.property.data.surface && (
                  <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                    Surface :{' '}
                    <span
                      style={{ fontSize: 16, color: '#171717' }}
                    >{`${selectedOdm.data.property.data.surface}m2`}</span>
                  </Typography>
                )}

                {selectedOdm.data.property.data.furnished && (
                  <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                    Meublé :{' '}
                    <span
                      style={{ fontSize: 16, color: '#171717' }}
                    >{`${selectedOdm.data.property.data.furnished ? 'Oui' : 'Non'}`}</span>
                  </Typography>
                )}

                {selectedOdm.data.property.data.lot_number && (
                  <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                    Lot n° :{' '}
                    <span
                      style={{ fontSize: 16, color: '#171717' }}
                    >{`${selectedOdm.data.property.data.lot_number}`}</span>
                  </Typography>
                )}

                {selectedOdm.data.property.data.associated_lots && (
                  <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                    'Lot(s) associés' :{' '}
                    <span
                      style={{ fontSize: 16, color: '#171717' }}
                    >{`${selectedOdm.data.property.data.associated_lots}`}</span>
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* ///// LOCATAIRES */}
            {selectedOdm?.data?.residents?.length > 0 && (
              <Grid item xs={layoutWithPannel || window.innerWidth < 600 ? 12 : 6} sx={{}}>
                <Grid item xs={12}>
                  <svg
                    style={{ position: 'relative', top: '5px', right: '0px', paddingRight: '5px' }}
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.4444 7.2H30.6667V4H27.1111V7.2H12.8889V4H9.33333V7.2H7.55556C5.58222 7.2 4 8.64 4 10.4V32.8C4 34.56 5.58222 36 7.55556 36H32.4444C34.4 36 36 34.56 36 32.8V10.4C36 8.64 34.4 7.2 32.4444 7.2ZM20 12C22.9511 12 25.3333 14.144 25.3333 16.8C25.3333 19.456 22.9511 21.6 20 21.6C17.0489 21.6 14.6667 19.456 14.6667 16.8C14.6667 14.144 17.0489 12 20 12ZM30.6667 31.2H9.33333V29.6C9.33333 26.4 16.4444 24.64 20 24.64C23.5556 24.64 30.6667 26.4 30.6667 29.6V31.2Z"
                      fill="#062746"
                    />
                  </svg>

                  <span style={{ fontSize: '20px', bottom: '5px' }}>
                    {selectedOdm?.data?.residents?.length > 1 ? 'Locataires' : 'Locataire'}
                  </span>
                </Grid>
                {selectedOdm?.data?.residents?.map((resident, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                        {`Locataire ${selectedOdm?.data?.residents.length > 1 ? index + 1 : ''} : `}
                        <span style={{ fontSize: 16, color: '#171717' }}>{`${resident.name}`}</span>
                      </Typography>
                      <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                        {`Email : `}
                        <span style={{ fontSize: 16, color: '#171717' }}>{`${resident.email}`}</span>
                      </Typography>
                      <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                        {`Tel : `}
                        <span style={{ fontSize: 16, color: '#171717' }}>{`${resident.phone1}`}</span>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        )}

        {/* GenralState */}
        {panelData.name === 'generalState' && (
          <Grid item xs={12}>
            {datas?.generalState[0].cleanliness_state && (
              <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                Etat de propreté général :{' '}
                <span style={{ fontSize: 16, color: '#171717' }}>{`${datas?.generalState[0].cleanliness_state}`}</span>
              </Typography>
            )}
            {datas?.generalState[0].observations && (
              <Typography variant="body1" style={{ fontSize: 12, paddingLeft: '48px', color: '#929292' }}>
                Observations :{' '}
                <span style={{ fontSize: 16, color: '#171717' }}>{`${datas?.generalState[0].observations}`}</span>
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

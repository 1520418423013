import AppRouter from './AppRouter';
import './App.css';

/* Theme variables */
import '../theme/variables.css';

import { ThemeProvider } from '@mui/material';
import { appTheme } from '../theme/theme';

const App = ({ ReactGA }) => {
  return (
    <div className="App">
      <ThemeProvider theme={appTheme}>
        <AppRouter ReactGA={ReactGA} />
      </ThemeProvider>
    </div>
  );
};

export default App;

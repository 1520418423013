import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api, getEdl } from '../../api';
import _ from 'lodash';
import MatterportViewerContainer from '../components/MatterportViewer/MatterportViewer';
// import MatterportViewerContainer from "./blocs/MatterportViewer/MatterportViewer";

const Edl3D = () => {
  const { idEdl } = useParams();
  const [odmDatas, setOdmDatas] = useState(null);
  const [referencialEquipment, setReferencialEquipment] = useState(null);
  const [referencial, setReferencial] = useState(null);
  const { REACT_APP_MATTERPORT_KEY } = process.env;
  const [datas, setDatas] = useState(null);

  useEffect(() => {
    api.referencial.equipments.get().then((data) => {
      console.log(data);
      setReferencialEquipment(data.datas);
    });
    api.referencial.get().then((data) => {
      console.log(data);
      setReferencial(data);
    });
  }, []);

  useEffect(() => {
    getEdl(idEdl).then((data) => {
      console.log(data);
      setOdmDatas(data);
    });
  }, [idEdl]);

  useEffect(() => {
    if (referencialEquipment && odmDatas && referencial) {
      console.log(referencialEquipment);
      console.log(odmDatas);

      const categories = [];
      const annexes = [];
      const externalEquipments = [];
      const generalEquipments = [];
      const meters = [];
      const keys = [];
      const inventoryEquipments = [];
      const degradations = [];

      const tags = [];

      odmDatas.edl.equipments.forEach((equipment) => {
        if (equipment.states && equipment.states.length > 0) {
          if (equipment.states[0].tags && equipment.states[0].tags.length > 0) {
            equipment.states[0].tags.forEach((tag) => {
              tags.push(tag);
            });
          }
        }

        let hasbeenAddedInExternals = false;
        referencialEquipment.externalEquipments.forEach((externalEquipment) => {
          if (externalEquipment.idEquipment === equipment.kind && equipment.tab === 'externalEquipments') {
            if (!hasbeenAddedInExternals) {
              console.log(equipment.kind, equipment);
              externalEquipments.push(equipment);
              equipment.name = _.find(referencialEquipment.equipmentsKind, {
                id: equipment.kind
              }).name;
              hasbeenAddedInExternals = true;
            }
          }
        });

        let hasbeenAddedInGenerals = false;
        referencialEquipment.generalEquipments.forEach((generalEquipment) => {
          if (generalEquipment.idEquipment === equipment.kind && equipment.tab === 'generalEquipments') {
            if (!hasbeenAddedInGenerals) {
              generalEquipments.push(equipment);
              equipment.name = _.find(referencialEquipment.equipmentsKind, {
                id: equipment.kind
              }).name;
              hasbeenAddedInGenerals = true;
            }
          }
        });

        let hasbeenAddedInMeters = false;
        referencialEquipment.meters.map((meter) => {
          if (meter.idEquipment === equipment.kind && equipment.tab === 'meters') {
            if (!hasbeenAddedInMeters) {
              meters.push(equipment);
              equipment.name = _.find(referencialEquipment.equipmentsKind, {
                id: equipment.kind
              }).name;
              hasbeenAddedInMeters = true;
            }
          }
        });

        let hasbeenAddedInKeys = false;
        referencialEquipment.keys.map((key) => {
          if (key.idEquipment === equipment.kind && equipment.tab === 'keys') {
            if (!hasbeenAddedInKeys) {
              keys.push(equipment);
              equipment.name = _.find(referencialEquipment.equipmentsKind, {
                id: equipment.kind
              }).name;
              hasbeenAddedInKeys = true;
            }
          }
        });

        let hasbeenAddedInInventory = false;
        referencialEquipment.inventoryEquipments.map((inventoryEquipment) => {
          if (
            inventoryEquipment.idEquipment === equipment.kind &&
            equipment.tab === 'inventoryEquipments' &&
            equipment.states &&
            equipment.states[0] &&
            (equipment.states[0]?.data?.inventoryNumber || equipment.states[0]?.images?.length > 0)
          ) {
            console.log(inventoryEquipment);
            if (!hasbeenAddedInInventory) {
              inventoryEquipments.push(equipment);
              equipment.name = _.find(referencialEquipment.equipmentsKind, {
                id: equipment.kind
              }).name;
              hasbeenAddedInInventory = true;
            }
          }
        });
      });

      odmDatas.edl.rooms.forEach((room, indexRoom) => {
        room.equipments?.forEach((equipment, indexEquipment) => {
          equipment.category = room.name;
          equipment.room = room.name;
          degradations.push(equipment);
        });
      });

      console.log('externalEquipments', externalEquipments);
      console.log('generalEquipments', generalEquipments);
      console.log('meters', meters);
      console.log('keys', keys);
      console.log('inventoryEquipments', inventoryEquipments);
      console.log('tags', tags);
      console.log('rooms', odmDatas.edl.rooms);

      console.log(
        'fichiers',
        odmDatas.edl.files.filter((file) => file.category !== 'Etats des lieux')
      );

      setDatas({
        informations: [{ test: 'test' }],
        externalEquipments,
        generalEquipments,
        meters,
        keys,
        inventoryEquipments,
        generalState:
          odmDatas?.edl?.cleanliness_state || odmDatas?.edl?.observations
            ? [
                {
                  cleanliness_state: odmDatas?.edl?.cleanliness_state,
                  observations: odmDatas?.edl?.observations
                }
              ]
            : [],
        annexes: odmDatas.edl.files.filter((file) => file.category !== 'Etats des lieux'),
        degradations
      });
    }
  }, [referencial, referencialEquipment, odmDatas]);

  useEffect(() => {
    console.log('datas', datas);
  }, [datas]);

  return (
    <div>
      {referencial && referencialEquipment && odmDatas && idEdl && (
        <MatterportViewerContainer
          REACT_APP_MATTERPORT_KEY={REACT_APP_MATTERPORT_KEY}
          modelId={odmDatas.data.matterport_scan.model_id}
          productUUID={odmDatas.data.property.uuid}
          propertyData={odmDatas.data.property}
          // setSelectedDataOrder={edlDatas}
          selectedEdl={odmDatas.edl}
          selectedOdm={odmDatas}
          selectedDataOrder={null}
          uuidMatterportScan={odmDatas.data.matterport_scan.uuid}
          referencial={referencial}
          referencialEquipment={referencialEquipment}
          datas={datas}
        ></MatterportViewerContainer>
      )}
    </div>
  );
};

export default Edl3D;

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React, { Component } from 'react';
import { AddCircleRounded, CancelRounded, Delete } from '@mui/icons-material';
import { referentielTypes } from '../../../api';
import { SvgIcon, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { setupSdk } from '@matterport/sdk';

let matterTagAddedOnScene = false;
let addMatterTagModeBolean = false;
let tagSid = '';
let inputComponent = null;
let longClickStart = false;
let interval = null;
let delayBeforeAddMatterTag = 500;

const key = process.env.REACT_APP_MATTERPORT_KEY;
const UID_ORGANIZATION = process.env.REACT_APP_UIID_ORGANIZATION;

let componentDidMounted = false;
let iFrameContent = null;
let currentCameraPositionX = 0;
let previousCameraPositionX = 0;
let that = null;
let currentTagSid = '';

class MatterPort extends Component {
  constructor(props) {
    super(props);
    that = this;
    this.props = props;
    //
    this.matterPortIframe = React.createRef();
    this.circleIndicator = React.createRef();
    this.circleIndicatorGrey = React.createRef();
    this.circleIndicatorBackGround = React.createRef();
    this.circleIndicatorFill = React.createRef();

    //
    this.matterPortSDK = null;
    this.cameraPosition = null;
    this.cameraPositionOnInputStart = { x: 0, y: 0, z: 0 };
    this.pointerCoords = null;
    this.tagToNavigate = this.props.tagToNavigate;
    // this.setMpSdk = null;
  }
  state = {
    addMatterTagMode: false,
    sceneReady: false,
    tagSidToDelete: '',
    tagSidSelected: '',
    isPortrait: true
  };

  componentDidMount() {
    if (!componentDidMounted) {
      const {
        modelID,
        mpKey,
        mpHelp,
        dollHouse,
        mpHl,
        mpGt,
        mpHr,
        mpMls,
        mpF,
        mpNozoom,
        mpWh,
        mpSearch,
        mpLang,
        mpQs,
        getEventPayload,
        setSceneReadyParent,
        dimensions,
        setMpSdk
      } = this.props;

      console.log('qs', mpQs);

      this.setSceneReadyParent = setSceneReadyParent;

      this.dimensions = dimensions;
      if (this.dimensions.width > this.dimensions.height) {
        this.setState({ isPortrait: false });
      } else {
        this.setState({ isPortrait: true });
      }

      // iframeQueryParams: { tagNav: 0, search: 0, lang: 'fr', vr: 0, brand: 0, mls: 2 }

      this.matterPortIframe.current.src = `../../matterport/showcase.html?m=${modelID}&tagNav=0$qs=${mpQs}&search=0&brand=0&mls=2&play=1&applicationKey=${key}&mds=0&help=${
        this.props.mode === 'administrate' ? 0 : mpHelp
      }`;
      this.matterPortIframe.current.addEventListener('load', async (e) => {
        try {
          const mpSdk = await e.target.contentWindow.MP_SDK.connect(this.matterPortIframe.current, mpKey, '3.6');
          this.onshowcaseConnected(mpSdk, this.matterPortIframe.current);
          console.log(mpSdk);
          if (setMpSdk) setMpSdk(mpSdk);
        } catch (e) {}
      });
    }
  }

  componentDidUpdate() {
    this.dimensions = this.props.dimensions;

    if (this.matterPortSDK && this.props.tagToNavigate && this.props.tagToNavigate !== '')
      this.matterPortSDK.Mattertag.navigateToTag(currentTagSid, this.matterPortSDK.Mattertag.Transition.FLY);

    if (this.dimensions.width > this.dimensions.height && this.state.isPortrait) {
      this.setState({ isPortrait: false });
    } else if (this.dimensions.width < this.dimensions.height && !this.state.isPortrait) {
      this.setState({ isPortrait: true });
    }

    if (this.state.addMatterTagMode) {
      interval = setInterval(() => {
        if (
          this.cameraPosition &&
          this.cameraPositionOnInputStart.x === this.cameraPosition.position.x &&
          this.cameraPositionOnInputStart.y === this.cameraPosition.position.y &&
          this.cameraPositionOnInputStart.z === this.cameraPosition.position.z &&
          longClickStart
        ) {
          const nextShow = this.cameraPositionOnInputStart.time + delayBeforeAddMatterTag;
          const pcent = (delayBeforeAddMatterTag - (nextShow - new Date().getTime())) / delayBeforeAddMatterTag;
          const pathLength = this.circleIndicatorFill.current.getTotalLength();
          this.circleIndicatorFill.current.setAttribute('stroke-dashoffset', pathLength - pathLength * pcent);
          // this.circleIndicatorBackGround.current.setAttribute('opacity', pcent / 2);

          if (new Date().getTime() > nextShow && !matterTagAddedOnScene) {
            this.setState({ addMatterTagMode: false });

            const canVibrate = window.navigator.vibrate;
            if (canVibrate) window.navigator.vibrate(100);
            addMatterTagModeBolean = false;
            matterTagAddedOnScene = true;
            this.matterPortSDK.Mattertag.editOpacity(tagSid, 1);
            this.matterPortSDK.Mattertag.getData(tagSid).then((mt) => {
              mt.forEach((tag) => {
                if (tag.sid === tagSid) {
                  this.storeMatterTag(tag);
                }
              });
              tagSid = '';
              this.circleIndicator.current.setAttribute('visibility', 'hidden');
              this.circleIndicatorGrey.current.setAttribute('visibility', 'hidden');
              inputComponent.inputs.userNavigationEnabled = true;
              this.cameraPositionOnInputStart = { x: 0, y: 0, z: 0 };
            });
          }
        }
      }, 16);
    } else {
      clearInterval(interval);
    }

    this.refreshBillBorads();
  }

  readFileBase64 = (file, max_size) => {
    const max_size_bytes = max_size * 1048576;
    return new Promise((resolve, reject) => {
      if (file.size > max_size_bytes) {
        reject('file exceeds max size of ' + max_size + 'MB');
      } else {
        var fr = new FileReader();
        fr.onload = () => {
          const data = fr.result;
          resolve(data);
        };
        fr.readAsDataURL(file);
      }
    });
  };

  refreshBillBorads() {
    this.props.getMatterTagList.forEach((tag, index) => {
      let html = `
              <style>
                .container {
                  width: 100px;
                  height: 100px;
                  padding: 2px;
                  pointer-events: none;
                }
                .photo {
                  width: 100px;
                  height: 100px;
                  object-fit: contain;
                  pointer-events: none;
                }
              </style>
            `;
      const allFilesPromise = [];
      if (
        this.props.getFilesTags[index] &&
        this.props.getFilesTags[index].files[0] &&
        this.props.getFilesTags[index].files[0][0]
      ) {
        this.props.getFilesTags[index].files[0].forEach((file) => {
          if (file instanceof Blob) allFilesPromise.push(this.readFileBase64(file, 1000));
        });
        Promise.all(allFilesPromise).then((arrResult) => {
          arrResult.forEach((base64String) => {
            html = html + `<span class="container"><img class="photo" src="${base64String}"></img></span>`;
          });

          this.matterPortSDK.Mattertag.injectHTML(tag.sid, html, {
            size: {
              w: 112 * arrResult.length,
              h: 120
            }
          });
        });
      }
      // console.log(allFilesPromise);
    });
  }

  storeMatterTag(matterTag) {
    this.props.matterTagsList((names) => [
      ...names,
      {
        uuid_organization: UID_ORGANIZATION,
        uuid_owner: this.props.id_event,
        owner_kind: 'event',
        position: {
          x: matterTag.anchorPosition.x,
          y: matterTag.anchorPosition.y,
          z: matterTag.anchorPosition.z,
          steam_x: matterTag.anchorNormal.x,
          steam_y: matterTag.anchorNormal.y,
          steam_z: matterTag.anchorNormal.z
        },
        sid: matterTag.sid
      }
    ]);
  }

  deleteMatterTag() {
    this.matterPortSDK.Mattertag.remove(this.state.tagSidToDelete);
    this.setState({ tagSidToDelete: '' });
    this.props.matterTagsList((prev) => {
      let indexElToDelete = null;

      prev.forEach((el, index) => {
        if (el.sid === this.state.tagSidToDelete) {
          indexElToDelete = index;
        }
      });

      const newArr = [...prev];
      newArr.splice(indexElToDelete, 1);

      return [...newArr];
    });
  }

  cancelAddMaterTag() {
    this.circleIndicatorGrey.current.setAttribute('visibility', 'hidden');
    this.setState({ addMatterTagMode: false });
    this.matterPortSDK.Mattertag.remove(tagSid);
    tagSid = '';
  }

  addMattertag(e) {
    this.setState({ addMatterTagMode: true });
    addMatterTagModeBolean = true;

    e.preventDefault();

    const scale = 0.33;
    this.matterPortSDK.Tag.add({
      label: referentielTypes[this.props.getEventPayload().kind],
      description: this.props.getEventPayload().desc,
      anchorPosition: {
        x: this.pointerCoords.position.x,
        y: this.pointerCoords.position.y,
        z: this.pointerCoords.position.z
      },
      stemVector: {
        x: scale * this.pointerCoords.normal.x,
        y: scale * this.pointerCoords.normal.y,
        z: scale * this.pointerCoords.normal.z
      },
      color: { r: 1 / (250 / 241), g: 1 / (250 / 118), b: 1 / (250 / 51) }
    }).then((tag) => {
      // tag.click = (e) => {};
      tagSid = tag[0];
      this.matterPortSDK.Mattertag.editOpacity(tagSid, 0.5);
    });
  }

  addMattertagAdministrate(data) {
    const file = this.props.eventDatas.files[0];

    const media = file
      ? {
          src: process.env.REACT_APP_URL_MEDIAS + this.props.eventDatas.files[0].uri,
          type: this.matterPortSDK.Mattertag.MediaType.PHOTO
        }
      : null;

    const scale = 0.33;
    this.matterPortSDK.Tag.add({
      // label: this.props.eventDatas.title,
      // description: this.props.eventDatas.desc,
      // media: media,
      anchorPosition: {
        x: data.position.x,
        y: data.position.y,
        z: data.position.z
      },
      stemVector: {
        x: scale * data.position.steam_x,
        y: scale * data.position.steam_y,
        z: scale * data.position.steam_z
      },
      color: { r: 1 / (250 / 3), g: 1 / (250 / 104), b: 1 / (250 / 125) }
    }).then((tag) => {
      currentTagSid = tag[0];
      this.matterPortSDK.Tag.allowAction(tag[0], {
        navigating: true
        // implies opening: false, etc
      });
      setTimeout(() => {
        this.matterPortSDK.Mattertag.navigateToTag(tag[0], this.matterPortSDK.Mattertag.Transition.FLY);
      }, 500);
    });
  }

  iDown = (e) => {
    if (addMatterTagModeBolean === true) {
      this.cameraPositionOnInputStart = {
        x: this.cameraPosition.position.x,
        y: this.cameraPosition.position.y,
        z: this.cameraPosition.position.z,
        time: new Date().getTime()
      };
      longClickStart = true;
      const pathLength = this.circleIndicatorFill.current.getTotalLength();
      this.circleIndicatorFill.current.setAttribute('stroke-dashoffset', pathLength);
      this.circleIndicatorFill.current.setAttribute('stroke-dasharray', pathLength);
      this.circleIndicatorBackGround.current.setAttribute('opacity', 0);
      this.circleIndicator.current.setAttribute('visibility', 'visible');
      this.circleIndicatorGrey.current.setAttribute('visibility', 'visible');
    }
    if (e.targetTouches && e.targetTouches.length > 0) {
      this.circleIndicator.current.style.left = `${
        e.targetTouches[0].clientX - 100 //+ node.offsetParent.getBoundingClientRect().left
      }px`;
      this.circleIndicator.current.style.top = `${
        e.targetTouches[0].clientY - 100 //+ node.offsetParent.getBoundingClientRect().top
      }px`;
    } else {
      this.circleIndicator.current.style.left = `${e.clientX - 100}px`;
      this.circleIndicator.current.style.top = `${
        e.clientY - 100 //+ node.offsetParent.getBoundingClientRect().top
      }px`;
    }
  };

  stopLongClick = (e) => {
    // console.log(e);
    if (addMatterTagModeBolean === true) {
      longClickStart = false;
      // navigateToMatterTag = false;
      this.circleIndicator.current.setAttribute('visibility', 'hidden');
      this.circleIndicatorGrey.current.setAttribute('visibility', 'hidden');
    }

    var node = document.getElementById('showcase');

    if (e.targetTouches && e.targetTouches.length > 0) {
      // console.log(node.offsetParent.getBoundingClientRect(), e.touches[0].clientX, e.touches[0].clientY)
      this.circleIndicator.current.style.left = `${
        e.targetTouches[0].clientX - 100 //+ node.offsetParent.getBoundingClientRect().left
      }px`;
      this.circleIndicator.current.style.top = `${
        e.targetTouches[0].clientY - 100 //+ node.offsetParent.getBoundingClientRect().top
      }px`;
    } else {
      this.circleIndicator.current.style.left = `${e.clientX - 100 + node.offsetParent.getBoundingClientRect().left}px`;
      this.circleIndicator.current.style.top = `${e.clientY - 100 + node.offsetParent.getBoundingClientRect().top}px`;
    }
  };

  onshowcaseConnected = (sdk, iFrame) => {
    // console.log(sdk, this, iFrame);
    this.matterPortSDK = sdk;
    this.setMpSdk = sdk;

    // console.log(this.matterPortSDK);
    // console.log(this.matterPortIframe.current);

    this.matterPortSDK.Camera.pose.subscribe((pose) => {
      // console.log(pose.position.x);

      currentCameraPositionX = pose.position.x;

      if (previousCameraPositionX == currentCameraPositionX) {
        // console.log("camera stopped");
      } else {
        clearInterval(interval);
        // console.log("camera moved");
        previousCameraPositionX = currentCameraPositionX;
        // console.log(this.state.tagSidToDelete);
        this.setState({ tagSidToDelete: '' });
      }
      this.cameraPosition = pose;
    });

    // this.matterPortSDK.Room.current.subscribe(function (currentRooms) {
    //   if (currentRooms.rooms.length > 0) {
    //     console.log('currently in', currentRooms.rooms.length, 'rooms');
    //   } else {
    //     console.log('Not currently inside any rooms');
    //   }
    // });

    const bodyIframe = this.matterPortIframe.current.contentWindow.window.document.body;

    iFrameContent = bodyIframe;

    bodyIframe.addEventListener('mousedown', this.iDown);
    bodyIframe.addEventListener('mousemove', this.stopLongClick);
    bodyIframe.addEventListener('mouseup', this.stopLongClick);
    bodyIframe.addEventListener('touchstart', this.iDown);
    bodyIframe.addEventListener('touchmove', this.stopLongClick);
    bodyIframe.addEventListener('touchend', this.stopLongClick);
    // bodyIframe.addEventListener("mousemove", this.stopLongClick);

    this.matterPortSDK.Pointer.intersection.subscribe((intersectionData) => {
      matterTagAddedOnScene = false;
      // this.matterPortSDK.Mattertag.getData().then(() => {
      //   if (tagSid !== "") {
      //     const scale = 0.33;
      //     this.matterPortSDK.Mattertag.editPosition(tagSid, {
      //       anchorPosition: {
      //         x: intersectionData.position.x,
      //         y: intersectionData.position.y,
      //         z: intersectionData.position.z,
      //       },
      //       stemVector: {
      //         x: scale * intersectionData.normal.x,
      //         y: scale * intersectionData.normal.y,
      //         z: scale * intersectionData.normal.z,
      //       },
      //     });
      //   }
      // });

      if (tagSid !== '') {
        const scale = 0.33;
        this.matterPortSDK.Tag.editPosition(tagSid, {
          anchorPosition: {
            x: intersectionData.position.x,
            y: intersectionData.position.y,
            z: intersectionData.position.z
          },
          stemVector: {
            x: scale * intersectionData.normal.x,
            y: scale * intersectionData.normal.y,
            z: scale * intersectionData.normal.z
          }
        });
      }
      // console.log(intersectionData);
      this.pointerCoords = intersectionData;
    });

    this.matterPortSDK.App.state.subscribe((appState) => {
      // console.log(appState);
      if (appState.phase === 'appphase.playing') {
        this.setState({
          sceneReady: true
        });

        this.setSceneReadyParent(true);

        // console.log("READY to archive");

        setTimeout(() => {
          this.props.setLoading(false);
        }, 100);
      }
    });

    this.matterPortSDK.Mattertag.data.subscribe({
      onAdded: function (index, item, collection) {},
      onRemoved: function (index, item, collection) {}
    });

    const sceneObject = this.matterPortSDK.Scene.createObjects(1);
    sceneObject.then((sceneObjectArr) => {
      const mySceneObject = sceneObjectArr[0];
      const node = mySceneObject.addNode('custom');
      inputComponent = node.addComponent('mp.input', {
        eventsEnabled: true,
        userNavigationEnabled: true
      });
      mySceneObject.start();
    });

    this.matterPortSDK.on(this.matterPortSDK.Mattertag.Event.CLICK, (sid) => {
      this.setState({ tagSidToDelete: sid });
      this.setState({ tagSidSelected: sid });
    });

    if (this.props.mode === 'administrate') {
      this.props.eventDatas.tags.forEach((tagdata) => {
        this.addMattertagAdministrate(tagdata);
      });
    }
  };

  render() {
    const { sceneReady, addMatterTagMode, tagSidToDelete } = this.state;

    return (
      <Grid container spacing={0}>
        <div
          style={{
            border: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: '0px',
            top: '0px'
          }}
        >
          {sceneReady &&
            this.props.mode !== 'administrate' &&
            this.props.mode !== 'view3D' &&
            this.props.mode !== 'remoteClient' && (
              <div
                id="AU_helper_container"
                style={{
                  position: 'absolute',
                  color: 'white',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  bottom: '230px',
                  pointerEvents: 'none',
                  fontSize: '0.8em'
                  // visibility: "hidden",
                }}
              >
                {!addMatterTagMode && this.props.getMatterTagList.length === 0 && (
                  <div
                    id="AU_Helper"
                    style={{
                      position: 'fixed',
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                      color: 'white',
                      width: '320px',
                      // margin: '0 auto',
                      padding: '2px',
                      borderRadius: '10px',
                      textAlign: 'center',
                      bottom: '67px'
                    }}
                  >
                    Cliquer sur le bouton + pour entrer en mode ajout de tag
                  </div>
                )}

                {addMatterTagMode && this.props.getMatterTagList.length === 0 && (
                  <div
                    id="AU_Helper"
                    style={{
                      position: 'fixed',
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                      color: 'white',
                      width: '390px',
                      margin: '0 auto',
                      padding: '2px',
                      borderRadius: '10px',
                      textAlign: 'center',
                      bottom: '67px'
                    }}
                  >
                    Appuyer pendant une seconde à un endroit pour positionner le tag
                  </div>
                )}

                {this.props.getMatterTagList.length > 0 && (
                  <div
                    id="AU_Helper"
                    style={{
                      position: 'fixed',
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                      color: 'white',
                      margin: '0 auto',
                      padding: '2px',
                      width: '220px',
                      borderRadius: '10px',
                      textAlign: 'center',
                      bottom: '67px'
                    }}
                  >
                    Cliquer sur la flèche pour continuer
                  </div>
                )}
              </div>
            )}

          <iframe
            title="matterport"
            id="showcase"
            width="100%"
            height="100%"
            frameBorder="0"
            allow="xr-spatial-tracking"
            allowFullScreen
            ref={this.matterPortIframe}
          ></iframe>

          {this.props.mode !== 'administrate' && this.props.mode !== 'view3D' && this.props.mode !== 'remoteClient' && (
            <div
              id="tagMenuContainer"
              style={{
                position: 'absolute',
                left: 'calc(50% - 46px)',
                bottom: this.state.isPortrait ? '88px' : '20px'
              }}
            >
              {sceneReady && !addMatterTagMode && tagSidToDelete === '' && (
                <SvgIcon
                  onClick={this.addMattertag.bind(this)}
                  sx={{
                    fontSize: 32,
                    color: '#F17633',
                    backgroundColor: 'white',
                    borderRadius: '30px',
                    padding: '0',
                    border: 0,
                    margin: '0',
                    '&:hover': {
                      cursor: 'pointer',
                      color: 'white',
                      backgroundColor: '#F17633'
                    }
                  }}
                >
                  <AddCircleRounded sx={{ fontSize: 32 }} />
                </SvgIcon>
              )}
              {sceneReady && tagSidToDelete !== '' && (
                <SvgIcon
                  onClick={this.deleteMatterTag.bind(this)}
                  sx={{
                    fontSize: 32,
                    color: '#F17633',
                    backgroundColor: 'white',
                    borderRadius: '30px',
                    padding: '0',
                    border: 0,
                    margin: '0',
                    '&:hover': {
                      cursor: 'pointer',
                      color: 'white',
                      backgroundColor: '#F17633'
                    }
                  }}
                >
                  <Delete sx={{ fontSize: 32 }} />
                </SvgIcon>
              )}

              {sceneReady && addMatterTagMode && (
                <SvgIcon
                  onClick={this.cancelAddMaterTag.bind(this)}
                  sx={{
                    fontSize: 32,
                    color: '#F17633',
                    backgroundColor: 'white',
                    borderRadius: '30px',
                    padding: '0',
                    border: 0,
                    margin: '0',
                    '&:hover': {
                      cursor: 'pointer',
                      color: 'white',
                      backgroundColor: '#F17633'
                    }
                  }}
                >
                  <CancelRounded sx={{ fontSize: 32 }} />
                </SvgIcon>
              )}
            </div>
          )}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            ref={this.circleIndicator}
            version="1.1"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              pointerEvents: 'none',
              transform: 'rotate(-90deg)'
            }}
            width="200"
            height={200}
            visibility="hidden"
          >
            <circle
              ref={this.circleIndicatorGrey}
              cx="100"
              cy="100"
              r="98"
              stroke="grey"
              strokeWidth="5"
              fill="none"
              visibility="hidden"
            />
            <circle
              ref={this.circleIndicatorBackGround}
              cx="100"
              cy="100"
              r="98"
              strokeWidth="0"
              fill="white"
              opacity={0.1}
            />
            <circle
              ref={this.circleIndicatorFill}
              cx="100"
              cy="100"
              r="98"
              stroke="#F17633"
              strokeWidth="5"
              fill="none"
              strokeDasharray={450}
              strokeDashoffset={450}
            />
          </svg>
        </div>
      </Grid>
    );
  }
}

export default MatterPort;
